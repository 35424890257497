export const warCrimesData = [
  {
    date: "1985 06 01",
    description: "Jayawardane/Rajiv Gandhi Summit - THIMPU TALKS",
    title: "PEACE TALKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 01",
    description: "The resumption of hostilities between LTTE & armed forces",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 06 01",
    description:
      "LTTE terrorists attack village of ELUVANKULAM in the PUTTALAM area killing 08 civilians including 3 children. 08 of the dead bodies were put into a house and set on fire.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 01",
    description0:
      "LTTE explode a vehicle bomb at MARADANA in the heart of COLOMBO killing 38 civilians.",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 01",
    description:
      "Sri Lanka Armed Forces advance towards South of KILINOCHCHI, which had been attacked by LTTE. During the confrontation, some 150-200 terrorists were killed. 40 soldiers died and 415 were injured.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 01",
    description:
      "Operation Indra Sera launched in BATTICALOA district to clear the Jungle Bases of LTTE killing 33 LTTE",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 01",
    description:
      "Sri Lanka Armed Forces launch Operation Saviya Balaya in BATTICALOA District in two phases.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 02",
    description:
      "LTTE attack a village in the 3rd Mile Post, Trincomalee. 10 Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 06 02",
    description:
      "Massacre of 31 novice Buddhist Priests and 04 civilians at Aranthalawa, Ampara",
    title: "LTTE MASSACRES",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 06 03",
    description:
      "India oppose military offensive (Vadamarachchi) India violates Sri Lanka's sovereignty by dropped food parcels by air threatening Sri Lanka not to take action   ",
    title: "INDIA INTERVENTION ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 06 03",
    description:
      "LTTE activate a claymore mine at a bus which was proceeding from MADAGAL to JAFFNA. (Ilavalai) ",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 06 04",
    description:
      "Mr. SINNATHAMBI THILAGAR –UNP, Candidate for Local Govt Elections Jaffna - 1983. Killed by LTTE",
    title: "LTTE ASSASSINATIONS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1985 06 04",
    description:
      "LTTE attacked a Sinhala settlement in Dehiwatta, Trincomalee. 15 Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 04",
    description:
      "LTTE attack ANANDANKULAM Sinhala village in Andankulam, Trincomalee. 17 unarmed Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 05",
    description:
      "LTTE attaked troops deployed at CTB Depot, KILINOCHCHI killing 1and injuring 4 soldiers. 3 soldiers who were seriously injured were sent to ANURADHAPURA Hospital. Troops continued to Counter Attack.  ",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 05",
    description:
      "Fast Attack Craft proceeding from Kankasanthurai escorting 600 Security Forces personnel detect a cluster of LTTE boats. The passenger crafts were redirected to return and the Fast attack crafts engaged the terrorist boats with the assistance from another two Fast Attack Crafts (P 496 & P 482) which were deployed off Nagarkovil. Additional craft from Kankasanthurai and Trincomalee were immediately dispatched to the location. Air Force was requested for air assistance. During this confrontation one terrorist boat was disabled but two other clusters of terrorist boats approached from Chalai. Fast Attack Craft (P 482) sustained heavy damages and was disabled. P 496 had managed to pullout the craft seaward and rescued 11 surviving crew members of a total of 16. Due to the extent of damage, P 482 sank around 0815 hours approximately 18.5 nautical miles from CHALAI at a depth of 40m. Confrontation continued and P 496 was also destroyed at approximately 1115 hours 14.5 nautical miles North of CHALAI due to subsequent action. ",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 05",
    description: "OPERATION KINIHIRA V Stage I (NUNAVIL WEST)",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 06 05",
    description:
      "Batticoloa- Troops at a Food Operation Base was attacked by a group of terrorists. Reinforcements sent to the location too were caught in an ambush at KAVATAMUNAI village. 07 x security forces killed in action, 09 x Security Forces wounded in action, 01 x Civilian killed, 15 x civilians wounded in the cross fire. ",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 06 06",
    description: "Attack on MANDATIVU Camp by LTTE..",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2008 06 06",
    description: "Moratuwa bus bombing - 22 innocent passengers killed",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 06 06",
    description:
      "OTHIYAMALAI (WLO) - Troops ambushed a terrorists vehicle and they believed 08 x Terrorists killed in action, confirmed deaths of Capt OLIYAN, Lt PARTHIBAN and 2/Lt APPA.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 07",
    description:
      "Mr. KANAPATHIPILLAI NAVARATNE RAJAH @ ROBERT - TELO, Member of Pradeshya Sabha, Arapathi, Batticaloa killed by LTTE. ",
    title: "LTTE ASSASSINATIONS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 07",
    description:
      "The Hon. Min. of Industrial Development Mr. C V GOONARATNE and 24 others were killed when a LTTE Suicide Bomber exploded himself at the Maligawa Road Junction on Galle Road, RATMALANA, to mark the War Heroes Day. Mrs. GOONARATNE also died in the attack. ",
    title: "LTTE ASSASSINATIONS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 06 08",
    description: "Bomb explosion in the Night Mail Train JAFFNA to COLOMBO.",
    title: "LTTE TRAIN BOMBS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 06 09",
    description:
      "PM RANIL WICKRAMASINGHE flew to India for a five day official visit. He held discussions with Indian President R K NARAYANAN, PM, AB VAJPAYEE, Foreign Minister JASWANT SINGH, Opposition Leader Mrs SONIA GANDHI and TAMIL NADU Chief Minister JAYALALITHA JAYARAM. Important matters mooted in this discussion were signing of MoU between the Indian Oil Corporation and Ceylon Petroleum Corporation. To manage and operate on a long lease bases the TRINCOMALEE Oil Farms and preliminary study regarding the proposed land bridge between the two countries.",
    title: "INDO-LANKA TALKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 06 10",
    description:
      "The LTTE concentrates its counter attacks on VAVUNIYA/KILINOCHCHI axis targeting Security Forces logisties in the belief that it will eventually force the SF to abort its mission. (Thandikulam)",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 06 10",
    description:
      "1st Counter Attack by LTTE on Operation Jayasikurui at THANDIKULAM.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 06 10",
    description: "Operation RANAGOSA IV Stage I was launched.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 11",
    description:
      "Two bomb explosions in two CTB buses, in front of the Bank of Ceylon and the other in close proximity to the SP Office, along Inner Harbour Road, Trincomalee, almost simultaneously. The buses were on their way to KANTALAI and COLOMBO respectively. 22 Civilians were killed. 75  Civilians were wounded.",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 06 11",
    description:
      "Private Van Bearing No. 38 - 496), proceeding from HOROWPATANA to TRINCOMALEE was blasted by a Pressure Mine at VEPPANKULAM, Trinomalee. 13 x Civilians and 01x Soldier were killed. ",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 11",
    description:
      "Resumption of Hostilities - attack on Police Stations and Security Forces Camps in the Eastern Province killing 600 Police Officers. H E R Premadasa ask police to surrender to the LTTE having struck a deal with the LTTE One of the most gruesome killings in the history of Sri Lanka. The massacred policemen were put into pits and shot one after the other. ",
    title: "LTTE MASSACRES - 600 POLICEMEN",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 11",
    description: "Attack on Kiran Army Camp by LTTE",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 11",
    description:
      "LTTE started neutralisation  of other militant groups - by killing its leaders",
    title: "LTTE KILLING TAMILS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 06 11",
    description:
      "Mr. PATHMANANDAN - Chairman of Thirukkovil Pradeshiya Sabhaw was shot dead at Thirukkovil, Ampara.",
    title: "LTTE KILLING TAMILS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 06 11",
    description:
      "LTTE launch an underwater suicide attack on 03 boats, which were anchored in the KARAINAGAR JETTY.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 06 11",
    description: "LTTE attack village of Eluvankulama in Puttalam",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 06 12",
    description:
      "LTTE surround Godapotha Temple in Polonnaruwa  08 x Civilians were killed and 06 civilians were wounded.",
    title: "LTTE TEMPLE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 06 12",
    description:
      "Bomb explosion in MANMUNAI FERRY at KOKKADICHOLAI, BATTICALOA. 10 x Civilians were killed.",
    title: "LTTE FERRY ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 12",
    description: "Recovery of SA 14 Anti Aircraft missile in MANNAR.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 14",
    description:
      "LTTE Suicide bomber explodes him targeting an Air Force Bus at WATTALA Junction. An Officer and 03 Airmen sustained minor injuries. 02 x civilians were killed.",
    title: "LTTE SUICIDE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 14",
    description:
      "An abortive attempt by a person on a pedal bicycle with an explosive device at the GAMUNU Cinema junction on COLOMBO - NEGOMBO road, WATTALA targeting a SLAF bus loaded with SLAF sick personnel.",
    title: "LTTE SUICIDE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 15",
    description:
      "MANNAR Old Pier Detachment in Thalaimannar was overrun by LTTE.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2006 06 15",
    description: "Kebethigollewa massacre - 66 innocent civilians killed",
    title: "LTTE MASSACRES",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 06 18",
    description:
      "A vehicle belonging to Survey Department was fired upon by a group of terrorists while it was returning from KANTALE to  KALLAR. (Meegasgodella)",
    title: "LTTE ATTACK",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 19",
    description:
      "Mr. V K YOGASANGARI. EPRLF, MP for JAFFNA District was shot dead in MADRAS, INDIA by LTTE.",
    title: "LTTE KILLING TAMILS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 19",
    description:
      "Mr. PERIYATHAMBI KIRUBAKARAN, Finance Minister of North East Provincial Council was shot dead in Madras, INDIA by LTTE..",
    title: "LTTE KILLING TAMILS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 06 20",
    description:
      "Cease Fire Agreement in progress - Dispute between the SLNavy and the LTTE after SLN personnel attack the LTTE cadres in KAYTS. Navy Commander called for probe.",
    title: "CEASEFIRE AGREEMENT VIOLATIONS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 21",
    description:
      "LTTE attack the village in Wilgamvehera, Trincomalee. 09 x Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 06 21",
    description:
      "LTTE fire at a Sinhala villages in Godapotha, Beruwila, Polonnaruwa.  ",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 06 21",
    description:
      "LTTE terrorists attacked the KONDACHCHI Detachment, Mannar using small arms and mortars. ",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 06 21",
    description:
      "An Explosive laden ISUZU ELF Truck driven by an LTTE Suicide cadre exploded on the road in front of the side entrance of Operational Headquarters of the Ministry of  Defence (Former JOC), Flower Road, COLOMBO 07. 12 x persons were killed and 85 x persons were wounded.",
    title: "LTTE SUICIDE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 06 21",
    description:
      "LTTE Office in MUTTUR came under attack. LTTE lodged a complaint with the SLMM.",
    title: "CEASEFIRE AGREEMENT VIOLATIONS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 06 22",
    description:
      "Atleast 50 SLA soldiers were killed and many others wounded in the fighting, An SLA Main Battle Tank was destroyed and two others were damaged, ",
    title: "Mankulam Battle ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 06 24",
    description:
      "LTTE attack the WELIGAHAKANDIYA Village in Periyapyllumalai, Ampara. 10 Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 06 24",
    description: "Counter Attack-2 on Op Jayasikurui at PERIYAMADU.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 06 25",
    description:
      "A Bomb explosion in the Sittaru/Kantale area in Trincomalee. 16 x Civilians were killed.",
    title: "LTTE VILLAGE ATTACKS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 06 25",
    description:
      "LTTE activat a claymore mine damaging a 55 Division convoy bus, which was plying from USSAN to PALALI on SARASALAI- PUTTUR road carrying personnel on leave. (North of Madduvil)",
    title: "LTTE BUS BOMBS ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 06 26",
    description: "Op RANAGOSA IV Stage II was launched.",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 26",
    description:
      'The ship "MV UHANA" carrying a private cargo of food to Jaffna was attacked by LTTE suicide boats. The vessel finally sank in Point Pedro',
    title: "LTTE SHIP ATTACKS",
  },
  {
    date: "2006 06 26",
    description:
      "Army Deputy Chief of Staff Maj. Gen. Parami Kulatunga is assassinated by male suicide bomber in Pannipitiya - 4 killed & 07 injured ",
    title: "LTTE ASSASSINATIONS",
    images: [
      {
        src: "https://bp3.blogger.com/_yT9KEb0HKJw/RoE__mEW5VI/AAAAAAAAAGQ/ze3dIAnW-_g/s400/Lt.+Gen.+Parami+Kulatunga.jpg",
        caption: "Maj. Gen Parami Kulatunga",
      },
    ],
    videos: [
      {
        src: "https://www.youtube.com/watch?v=PFP3Ku0J9w0",
        type: "youTube",
      },
    ],
    links: [
      {
        href: "https://trinitylk.com/2011/06/lt-gen-parami-kulatunga/",
        title: "Lt. Gen. Parami Kulatunga, an epitome of military heroism",
      },
    ],
  },
  {
    date: "1995 06 28",
    description:
      "LTTE launched a major attack to the bunker line from North East and Eastern directions. Jaffna",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 29",
    description:
      "US Congress delegation visited Colombo to discuss efforts to fight HIV/AIDS. The delegation was chaired by Mr. BENJAMIN GILLMAN, who on 26th Jun 2000 wrote to Mr. MADELINE ALBRIGHT, Secretary US State Dept stating the LTTE is well positioned to take Jaffna and indicating the US to make it dear that it would support all options incident session to be discussed in the negotiation process.",
    title: "US STATEMENTS",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 06 29",
    description:
      "Senir Sea Tiger Ldr KANGAI AMARAN  killed due to a SF attack at VANNERIKULAM area. ",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 06 30",
    description:
      "Troops expand the existing Forward Defense Line in general area ETHAWETUNUWEWA, troops assaulted and captured LTTE strong points under heavy artillery mortar fire killing 16 LTTE",
    title: "SL ARMY - LTTE ",
    videos: [],
    images: [],
    links: [],
  },

  {
    date: "1957 07 01",
    description:
      '"Bandaranayake Chelvanayakam Pact. The main issues were federal constitution of regional autonomy, status of Tamil Language, citizenship and colonization."',
    title: "Bandaranaike-Chelvanayagam Agreement",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 01",
    description:
      '"The Implementation of INDO - SRI LANKA Accord. All Tamil Parties except the LTTE accepted the accord. Prabakaran kept in India until Agreement signed. The Indian Peace Keeping Force, which remained in Sri Lanka from 30th July 1987 until March 1990, then became involved in a protracted conflict with the LTTE and allied with other Tamil groups against them. IPKF was asked to leave by President Premadasa"',
    title: "Indo-Lanka Agreement",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 01",
    description:
      "Terrorists explode a parcel bomb on a train plying to the South killing 64 civilians - Dehiwela bomb attack",
    title: "LTTE Train Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 07 01",
    description:
      "The ship MV MISSEN chartered by Rehabilitation and Resettlement Authority for North (RRAN) to Ferry Tamil Refugees from Mannar o Kankasanthurai was set on fire by armed LTTE terrorosts who forcibly boarded the vessel.",
    title: "LTTE Ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 01",
    description:
      "A Liberty Bus bound to PALLAY was attacked by LTTE in NUNAVIL",
    title: "LTTE Bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 01",
    description:
      "Operation Ranagosa 4 (Stage III) launched in NADDAKANDAL area and captured 425 Sq kms.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 01",
    description:
      '"Suicide cadres destroyed a civilian passenger craft ""NEWCO ENDURANCE"" in TRINCOMALEE."',
    title: "LTTE suicide",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1982 07 02",
    description: "Killing of four Police Officers by LTTE.",
    title: "LTTE killing Police",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 02",
    description:
      '"The MDMK Conference on 1st and 2nd July was reportedly attended by the Indian Home Min. Mr. ADVANI, Mr. GOPALAN VAIKO stated the only inevitable solution would be TAMIL EELAM."',
    title: "LTTE-India",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 02",
    description:
      "Pakistan's General PARVAZ MUSHARAF attending Press Conference at Islamabad reassured that his country would continue to help Sri Lanka in combating terrorism and find a lasting solution to the conflict.",
    title: "Pakistan help for Sri Lanka",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 02",
    description:
      "KATTAPARICHCHAN (Trincomalee) - LTTE launch an attack at 64th Mile Post Police Detachment.",
    title: "LTTE attack police",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 03",
    description:
      '"S/9Q02195 L/CPL SAUNDARAJAH A, 9 SLNG a Soldier attached to Intel Group of HQ 233 Brigade was abducted by LTTE. When he was on duty at BATTICALOA Town. Further it was revealed that the said soldier had been tortured and was subsequently killed by the LTTE on 22/07/2002."',
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 04",
    description:
      "LTTE launch an attack on the naval craft “PABBATHA” by setting off under water explosive devices. (North of Pudukudurippu)",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 04",
    description:
      '"Abortive attempt on the life of Hon. Min. Mr. NIMAL SIRIPALA DE SILVA. A female suicide bomber exploded herself, killing Brig. HAMANGODA and 20 others. (Stanley Road, Jaffna)"',
    title: "LTTE assassination attempt - Nimal Siripala de Silva",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 04",
    description:
      "Troops launched an Operation to capture a Kovil Complex South of NAGARKOVIL.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 05",
    description:
      "An explosive laden lorry was driven by a suicide cadre named Vallipuram Vasanthan - Captain MILLAR into the security forces camp NELLIADI and exploded. (The first suicide attack of the LTTE) -",
    title: "LTTE suicide",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 07 05",
    description:
      "Mr. ARUNACHALAM THANGATHURAI – TULF MP for Trincomalee. (TCO) killed by LTTE.",
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 05",
    description:
      "A Telecommunication Tower located near the CHEDDIKULAM Post Office attacked by LTTE.",
    title: "LTTE attack post office",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 05",
    description:
      "LTTE hacked to death villagers who were travelling in a van. (Rathmalgahawella)",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 06",
    description:
      '"a group of LTTE cadres, including Seelan and Sellakili (Sathasivam Chelvanayakam), stole five exploders from Kankesanthurai Cement Factory"',
    title: "",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 07 06",
    description:
      '"Approx. 30 to 50 terrorists attacked a Muslim village in Puddur, Polonnaruwa 16 x Civilians were killed and 03 x Civilians were wounded."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 07 06",
    description:
      '"LTTE attacked a Sinhala village and abducted 10 x Civs and a Hi-ace Vehicle from Karapola & Manampitiya, in Polonnaruwa"',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 07 07",
    description:
      "MV ''MORON BONG'' a KOREAN ship was seized by the LTTE Later on 12/07/1997 all persons (38) were released in VAVUNIYA area. This vessel was released on 03/09/1997.",
    title: "LTTE Ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 08",
    description:
      '"LTTE fire at Monkey Bridge Sinhala village, Trincomalee. 15 x Civilians were killed."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 09",
    description:
      '"LTTE attack village in Mollipothna, Trincomalee. 16 x Civilians were killed."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 09",
    description:
      '"A CTB Bus leaving Jaffna was ambushed by LTTEs at Irattaperiyakulam, Vavuniya. 02 x Civilians were killed."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 09",
    description:
      "KODDAMUNAI BRIDGE (Batticoloa) - 02 x LTTE Pistol group cadres were shot and killed by Intelligence",
    title: "LTTE killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 10",
    description:
      '"Black Sea Tigers (KANTHARUBAN and group) on suicidal mission had attempted to ram an explosive laden boat on SLN Ship ""EDITHARA"". In Velvetiturai"',
    title: "LTTE suicide",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1991 07 10",
    description:
      '"LTTE attempted to blast the Elephant Pass, Security Force Camp. Pte GAMINI KULARATNE, SLSR of Hasalaka hurled himself with a grenade in his hand to hold the advance of  the armour plated and explosive laden bulldozer into the Camp Complex."',
    title: "SL Army - LTTE - Hasalaka Gamini",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 10",
    description:
      "Troops launch a Limited Operation to capture Terrorist Main Defence Line in general area CHEMMANIKULAM - COLOMBUTHURAI.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 10",
    description:
      "Attack on VILGAMVEHERA Detachment in Trincomalee Dist. 04 x civ Killed in action",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 11",
    description:
      "Attack on Kokavil Army Camp by LTTE.- bravery of Capt Saliya Aladeniya",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 11",
    description:
      '"LTTE launch an underwater suicide attack on 03 boats, which were anchored in the KARAINAGAR JETTY."',
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 11",
    description: "LTTE attack the village. (Eluvankulama) Puttalam",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 11",
    description:
      "MUNDALMADU - BATTICALOA LTTE Capt SUDA @ MULLAICHELIAN was killed by Securty Forces in an Ambush",
    title: "LTTE killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 11",
    description:
      '"V. GOPALASAMY (VAIKO), the Leader of MARUMALARCHY DRAVIDA MUNNETHRA KASAHAGAM (MDMK) was taken into custody for speaking in support of the LTTE which is a banned terrorists group in INDIA."',
    title: "India-LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 12",
    description:
      "KOKAVIL Detachment was over run by the LTTE - of the 54 soldiers stationed at the camp only 2 soldiers & a cook survived (52 soldiers killed by LTTE)",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 12",
    description:
      '"Security Forces Informant named KUMAR @ MADAN was shot and injured at PAMANKADA, COLOMBO 05. (LTTE Pistol Group)"',
    title: "LTTE assassination",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1985 07 13",
    description:
      "Peace Talks between GoSL and other terrorist groups   - Thimpu Talks",
    title: "Peace Talks - Thimpu",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 13",
    description:
      '"04 x Armed LTTE had come in a jeep to PAVAKKULAM, Trincomalee and killed 11 civilians"',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1989 07 13",
    description:
      '"Mr. SINNATHAMBI SAMBANDANMOORTHI, Chairman of District Development Council/Chief Organizer of the TULF was shot dead in Eravur, Batticoloa"',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1989 07 13",
    description:
      '"Mr. A. AMIRTHALINGAM, MP, Secretary General of the TULF/Former Opposition Leader and Mr. V. YOGESHWARAN – TULF,  MP for JAFFNA were shot dead by the LTTE in WIJERAMA MAWATHA, COLOMBO 07."',
    title: "LTTE killing Tamils - Amirathalingam",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 13",
    description:
      '"Two SLMM Naval Monitors, who had inspected a suspicious trawler at North of PESALAI on the request of SLN, had been abducted and held a whole night against their will by the LTTE."',
    title: "LTTE - SLMM",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 14",
    description:
      "Suicidal Attack on Naval Ship Edithara off Point Pedro by LTTE..",
    title: "LTTE ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 14",
    description:
      "A time bomb exploded just opposite the police barracks where a convoy of police busses was parked. (Batticoloa)",
    title: "LTTE attack police",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 15",
    description:
      '"Tip-off received by police to Brigadier J. G. Balthazar, the commander of the Sri Lankan security forces in the north that Seelan, the most wanted Tamil militant after Prabhakaran, was operating from a house in a coconut grove at Meesalai near Chavakachcher - he fell injured & ordered fellow cadre Aruna to shoot him in the head"',
    title: "",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 15",
    description:
      "Killing of LUCAS CHARLES ANTHONY (Seelan) before capture by SL troops - founding member of LTTE / the Charles Anthony regiment was formed in his honor & Prabakaran's son named after him",
    title: "LTTE killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 15",
    description:
      '"Mr. K KANAGARATNAM, MP for POTTUVIL was shot dead in POTTUVIL."',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1992 07 15",
    description:
      '"LTTE attack a Civil Bus proceeding from KATHANKUDY towards KALMUNAI at Kirankulam, Batticoloa 19 x ciilians were killed and 07 x civilians were wounded."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1994 07 15",
    description:
      '"Mr. VELEPODI ALAGIAH - TELO, Pradeshiya Sabha member was shot dead at MAVADIVEMBU, Batticoloa"',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 07 15",
    description:
      '"Mr. SARAVANABAWANANDAN  SHANMUGANATHAN @ VASANTHAN – PLOTE, MP for Vavuniya District. (By Claymore Mine) (Vavuniya) killed by LTTE"',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1989 07 16",
    description:
      '"Assassination of PLOTE Leader UMA MAHESHWARAN at WELLAWATTA, COLOMBO 06 - abducted by 6 men & dead body dumped at Frankfurt Place, Bambalapitiya"',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 16",
    description:
      "Terrorist launched a suicide attack by setting off an under water explosive device on SLN Ship EDITHARA. (OFF KANKASANTHURAI Harbour)",
    title: "LTTE ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 16",
    description:
      '"03 x PLOTE Members including a Leader sustained injuries when a bomb, which was fixed to a push bicycle, exploded by an unknown person in Vavuniya.  BASKARALINGAM, PLOTE Leader in Vavuniya.succumbed to his injuries."',
    title: "LTTE killing Tamils",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 16",
    description:
      '"Killed Mr. PIYASENA KARUNARATNE – Chief Organizer of EPDP. (THIRUNAVELI, JFN)"',
    title: "LTTE assassination",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 16",
    description:
      "LTTE Chief Negotiator ANTON BALASINGHAM discussed with the European Union Delegation in WANNI Matters discussed. Rehabilitation and Reconstruction of the North & East.",
    title: "Peace Talks -",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "186 07 17",
    description:
      '"LTTE explod a bomb in Block 4 Sugar Corp, TCO. 10 x Civilians were killed."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 17",
    description:
      '"Vavuniya Town - Troops searched a vehicle belonging to S/L RED CROSS, which was proceeding to uncleared area and recovered 96 x Pen Torch Batteries and 30 x rechargeable pen torch batteries hidden inside the vehicle."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 18",
    description:
      "A vehicle belonging to Survey Department was fired upon by a group of terrorists while it was returning from KANTALE to  KALLAR. (Meegasgodella)",
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 18",
    description:
      "Terrorists launched an attack on MULLAITIVU Camp - 500 soldirs are killed in the most gruesome manner by the LTTE",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 18",
    description:
      "An army truck moving from KALLAR to DEHIWATTA detachment was caught in a terrorist land mine. (Matara)",
    title: "",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 18",
    description:
      "An attempt by terrorists to blow up the TRINCOMALEE - COLOMBO mail train at PALLAIYUTTU failed when the mine exploded before the train could pass the location.",
    title: "LTTE train Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 18",
    description:
      "SAMANTHURAI (AMPARA) - LTTE lobbed a hand grenade at the residence of Mr. ULM MOHIDEEN MP for DIGAMADULLA. 04 x Police wounded in action.",
    title: "LTTE assassinations",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 19",
    description:
      '"Terrorists attacked a Sinhala village consisting of a Security Forces out Post in GODAPATHANA Village, Beruwil & Wadigawewa in Polonnaruwa. 17 x Civilians were killed."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 19",
    description:
      '"Sea Tigers had placed an underwater explosive device on the hull of  the  MV ""PRINCESS WAVE"" and exploded it. (Pulmoddai)"',
    title: "LTTE ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 19",
    description:
      '"The SLN Gun Boat ""RANAVIRU"" was attacked by Black Sea Tigers. (Off MLT)"',
    title: "LTTE ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 19",
    description:
      '"LTTE terrorists set fire to a bus, belonging to VAVUNIYA Depot at WEERAPURAM Village and the bus completely damaged. (CHEDDIKULAM)"',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 19",
    description:
      "ASIKULAM (Vavuniya) - LTTE fired and killed 04 x Home Guard girls. Whilst they were returning after duty from SIDAMAPURAM Post.",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 07 20",
    description:
      '"Mr. MEH MAHAROOF – UNP MP for TRINCOMALEE. (06th Mile Post, Nilaveli, TCO) killed by LTTE."',
    title: "LTTE assassinations",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 20",
    description:
      "PANDARIKULAM (MNR) - A bus which troops school children from UKKULANKULAM to VNA had been set fire and blasted the engine after the passengers on board were forcibly got down.",
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 20",
    description:
      '"A bus which was transporting school children from UKKULAMKULAM to VAVUNIYA had been halted by two armed terrorists at UKKULAMKULAM junction and ordered all passengers on board to leave the bus, and had set fire to it. (Pandarikulam)"',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 07 21",
    description:
      "A Time Bomb explosion in Bandula Hotel causing damage to the Building in Trincomalee Town. 05 x persons were killed and 15 x persons were wounded.",
    title: "LTTE public attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1992 07 21",
    description:
      '"Terrorists stopped the COLOMBO/ BATTICALOA Train at Prangiyamadu, BCO. and ordered  the passengers to get down and opened fire at the Muslim passengers. 08 v Civ were killed and 04 x Civ were wounded."',
    title: "LTTE train Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 21",
    description:
      "A CTB bus which was transporting passengers from RASENDRAKULAM to VAVUNIYA had been halted by 02 armed terrorists at RASENDRAKULAM and had ordered all passengers to get down from the bus and had set fire to it.",
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 22",
    description:
      '"Terrorists exploded a Land Mine on a Civilian Bus at MAMADUWA, Vavuniya. 32 x Civilians were killed. 02 x Civilians wounded."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 23",
    description:
      "Widespread violence erupted against Tamils after the killing of 13 soldiers in a LTTE ambush at Thiriniveli to avenge the death of Lucas Charles Anthony",
    title: "1983 July - 13 soldiers killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 23",
    description:
      "Killing of Selvanayagam @ Sellakkili @ Amman @ Chandran Leading Member and Op Comd in an ambush at Thinnaveli.",
    title: "LTTE killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1986 07 24",
    description:
      '"A Bomb explosion at Issenbessagala, Anuradhapura. 17 x Civilians were killed and 40 x Civilians were wounded."',
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 24",
    description:
      "Sinhala villagers were hacked to death by terrorists at Aralaganvila in Ampara. 08 x Civilians were killed.",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 24",
    description:
      "Bomb explosion on the ALUTHGAMA Train at DEHIWALA Station. Another bomb was defused by the Army Bomb Disposal Team. At the time the incident was informed 59 persons were killed 356 civilians were injured.",
    title: "LTTE train Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 24",
    description:
      '"Terrorists attacked the Sri Lanka Air Force Base at KATUNAYAKE and the Bandaranaike International Air Port around 0330 hours this morning. Air Force and Army troops managed to bring the situation under control and clearing operations. 02 x K\'fir Air Crafts, 02 x MI - 17 Air Crafts, 01 x MIG - 27 Air Craft, 3 x K8 Training Aircrafts, 02 x 340 Air Buses of Sri Lankan Air Lines, 01x 330 Air Bus of Sir Lankan Air lines. 14 LTTE killed, 7 Security Forces killed x 13 x Wounded, Civilians 04 x WIA (03 x Sinhala and 01x Russian Engineer)"',
    title: "LTTE Katunayake attack",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 07 25",
    description: "Communal riots.",
    title: "July 83 riots",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 25",
    description:
      "Civilians were hacked to death by terrorists at Meeyankulam in Polonnaruwa. 08 x Civilians were killed.",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 25",
    description:
      '"Villagers were hacked to death by terrorists at Wan-Ela, Trincomaee. 09 x Civilians were killed."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1993 07 25",
    description:
      '"Terrorists launched concentrated attacked on JANAKAPURA Camp in WLO. 24 x SF killed, 31 x SF wounded and 20 x SF missing."',
    title: "SL army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 25",
    description:
      '"A Passenger Craft ""NEWCO ENDURANCE” which was anchored at the TRINCOMALLEE Harbour was exploded by a LTTE Suicide Bomber."',
    title: "LTTE ship Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 25",
    description:
      '"LTTE shot dead a soldier named S/136578 Pte NILANTHA SH, GW dose to the LTTE Forward Defence Line at MUHAMALAI. The soldier was reported missing with his personal weapon."',
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 26",
    description:
      '"Terrorists hacked civilians to death in Thammennawa, Anuradhapura. 19 x Civilians were killed and 03 x Civilians were wounded."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 26",
    description:
      '"A meeting for the area Political Leaders in Jaffna Peninsula had been conducted by S P THAMIL SELVAN, Leader of the Political Wing of the LTTE at THEEPAN Camp in Kilinochchi. During this meeting THAMIL SELVAN had explained as to now they should deal with general Public during the period of ceasefire agreement."',
    title: "LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1975 07 27",
    description: "Assassination of Jaffna Mayor Alfred Duraiappah.",
    title: "LTTE killing Tamils - Duraiappah",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 27",
    description: "OP Leap Forward was launched in JAFFNA.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1988 07 28",
    description:
      '"Terrorist attack on the Sinhala village, Ethawetunuwewa, WeliOya. 16 x Civilians were killed and 01 x Civilian was wounded."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 28",
    description: "Abortive attack on KKS Camp by LTTE.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 28",
    description:
      '"A large number of terrorists attacked JANAKAPURA, KOKILAI, JAYASINGHEPURA, KOKKUTUDUVAI and PARACKRAMAPURA SF Camps."',
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 07 28",
    description: "Attack on Mullaitive Army Camp. (Oyatha Alaigal- I)",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 29",
    description:
      '"Terrorist attack on civilians at Thoppur, Trincomalee. 09 x Civilians were killed."',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 29",
    description: "Signing of Indo-Sri Lanka Peace Accord",
    title: "Indo-Lanka Agreement",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 29",
    description:
      '"Terrorists fired at the Mosque at Samanthurai, Ampara. 05 x Civilians were killed and 03 x Civilians were wounded."',
    title: "LTTE mosque attack",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 07 29",
    description:
      '"A LTTE Suicide Bomber exploded himself  killing Dr. NEELAN THIRUCHELVAM - TULF MP, National List/Constitutional Lawyer at the Kynsey Road Junction, Borella of the Rosmead Place."',
    title: "LTTE killing Tamils - Neelan T",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 07 29",
    description:
      '"Terrorists exploded a claymore mine, which had been fixed on to a tree, damaging a bus. (Karuvankerni)"',
    title: "LTTE bomb",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 07 29",
    description:
      "In THIRUKKOVIL (Ampara) on an ambush troops killed Lt Col GAVASKAR and 2/Lt PUSHPANADAN.",
    title: "LTTE killed",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 30",
    description:
      "PM Rajiv Gandhi escapes attempt on his life by Sri Lankan naval ratng during a military parade after signing Indo-Lanka Accord. Wijemuni Vijitha Rohana de Silva anger at signing the accord led to his action",
    title: "",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 30",
    description:
      '"Terrorists abducted and killed 14 Muslims at Akkaraipattu, Ampara"',
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1992 07 30",
    description:
      "Explosion of a bomb planted in a Private Bus at the Bus Stand at the Trincomalee Town. 9 civilians were killed and 4 civilians were wounded.",
    title: "LTTE bus Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 07 30",
    description:
      '"GOC 3 Div, Brigadier NKB Angammana whilst returning after visiting the MUKKARAL Army Detachment got caught to a pressure mine and he was criticaly injured. Due to the incident 01x officer and 05 x soldiers were wounded."',
    title: "LTTE assassinations - Brig Angammamana",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2002 07 30",
    description:
      "S/353082 L/CPL SAMAN SP 8 SLSR who was running around the island along the coast to create a Guinness Record and his two Supporters (ANIL KUMARA BANDARA and NIMAL UDUGAMSOORIYA) were arrested by the LTTE in MULLAITIVU.",
    title: "SL Army - LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 07 31",
    description: "Arrival of Indian Peace keeping Force (IPKF).",
    title: "IPKF arrive",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 07 31",
    description:
      "Terrorists fired at villagers in Podankady/Kantale in TCO. 11 x Civilians were killed.",
    title: "LTTE village Attacks",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1983 08 01",
    title: "6th amendment passed",
    description:
      "Sixth Amendment of the Constitution was formed which prohibited the advocacy of separatism.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 08 01",
    title: "Draft Indo-Lanka Accord",
    description:
      "Draft framework of terms of accord and understanding (DELHI Accord). The main issues were unit of devolution and extent of powers. The agreements reached were on unit of devolution and extent of powers.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1994 08 01",
    title: "New Govt ken to negotiate peace",
    description:
      "Willingness of the new government to end hostilities and arrive at a peaceful settlement to the ethnic conflict.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1997 08 01",
    title: "Operation Jayasikuru",
    description: "Counter attack on Op Jayasikurui at OMANTHAI.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 08 01",
    title: "LTTE attack troops",
    description:
      "Troops on limited operation had been attacked by LTTE terrorists killing 30 x soldiers. 110 x terrorists were killed in the confrontation.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 08 01",
    title: "Operation Dasabala",
    description: "Operation Dasabala launched in BATTICALOA district.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 08 01",
    title: "Operation Ranahanda",
    description:
      "Operation Ranahanda was launched to clear TRINCOMALEE area killing 10 x terrorists and again in Operation Siva Shakthi killing another 03 x terrorists.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 01",
    title: "Nedumaran arrested",
    description:
      "NEDUMARAN was arrested from his residence on August 01st for participating in a Pro – LTTE Conference in the City in April last. While the police raid his residences here and in Madurai on August 08 to find evidence linking him to the LTTE. (Internet Report – 13/08/2002)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 08 02",
    title: "LTTE attack Somawathiya Temple",
    description:
      '"Armed terrorists attacked RUHUNU SOMAWATHIYA Temple at Thrikonamadu, Polonnaruwa 06 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 08 02",
    title: "Operation Jayasikuru",
    description: "Counter attack on Operation Jayasikurui at MUNDUMURIPPU.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 03",
    title: "LTTE attack Kattankudy",
    description:
      "Terrorists massacre Muslim dwvotees at a Kathankudy Mosque in Batticoloa. 103 x Civilians were killed and 70 x Civilians were wounded..",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 08 03",
    title: "LTTE attack troops",
    description: "Counter Attack on Op troops in South of KILINOCHCHI.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 05",
    title: "LTTE kill civilians in Ampara",
    description:
      "Terrorists killed civilians working in a paddy field at Mullayankadu in AMP.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 06",
    title: "LTTE kill civilians in Ampara",
    description:
      "Terrorists killed civilians working in a paddy field at Ampara. 34 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 08 06",
    title: "LTTE explode claymore",
    description:
      "Terrorists exploded a claymore mine at a STF vehicle carrying persons on leave from MADHU to VAVUNIYA in general area KURUMANKADU. (VEPPANKULAM)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 07",
    title: "LTTE attack village in Ampara",
    description:
      "Attack on BANDARADUWA village in Ampara 34 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 08 07",
    title: "LTTE suicide attack",
    description:
      '"A suicide cadre pushing a cart fixed with a bomb exploded at the gate of the Ministerial Office, Independence Square, Colombo 07 of the Western Province Chief Minister. "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 08",
    title: "LTTE attack private coach ki lling civilians",
    description:
      '"A Private Coach proceeding from MORAWEWA to HOROWPATHANA was attacked by Terrorists at Meegaswewa, Trincomalee 26 x Civilians were killed and 07 x Civilians were wounded."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 08",
    title: "LTTE attack vilagers in Vavuniya",
    description:
      '"Terrorists attacked the villagers in Nawagamuwa, Vavuniya. 07 x Civilians were killed and 04 x were wounded."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1991 08 08",
    title: "LTTE kill Muslim farmers",
    description:
      '"08 Muslim farmers who where on their way home from their paddy fields were killed, West of Samanthurai in Ampara"',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1992 08 08",
    title: "LTTE pressure mine kills Maj. Gen. Denzil Kobbekaduwa",
    description:
      '"Pressure Mine explosion destroying a Military Vehicle (Land Rover) carrying Maj. Gen. D L KOBBEKADUWA and 08 Other Senior Officers. 09 + 01 Army killed, 03 x Navy killed and 01 x Army wounded."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 08 08",
    title: "LTTE suicide attack",
    description: "Suicidal bomb explosion in TORRINGTON SQUARE.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 08 08",
    title: "LTTE kills manager of CWE",
    description:
      "An unidentified gunman shot and killed the Manager of CWE Shop in Mannar named Mr. K. SUGU.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1992 08 09",
    title: "LTTE attack village",
    description:
      "Terrorists attack on the Mailanthenna village. 25 x Civ were killed and 10 x were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 08 09",
    title: "LTTE attack ship",
    description:
      "MV PRINCESS WAVE Ship was damaged due to an explosion carried by LTTE at Pulmoddai.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 08 09",
    title: "LTTE sea tigers attack ship",
    description:
      '"The LTTE Sea Tigers placed an underwater explosive device on the hull of the MV PRINCESS WAVE and exploded. (PULMODDAI, TCO)"',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 08 08",
    title: "LTTE suicide attack kills Maj. Karunanayake",
    description:
      '"A LTTE Suicide Bomber exploded himself  inside the VAKARAI Army Camp killing Maj. GTV KARUNANAYAKE 2 (V) SLLI, the Commanding Officer to the Camp. "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 08 09",
    title: "LTTE attack CTB bus",
    description:
      "A CTB Bus of SLN Road convoys was caught in a claymore mine explosion at ANP Junction in TCO.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 08 09",
    title: "LTTE attack in Vavuniya",
    description:
      "PIRAMANALANKULAM (VNA) Terrorist attacked the PIRAMANALANKULAM Detachment in Vavuniya",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 08 10",
    title: "LTTE attack in Ampara",
    description:
      '"Terrorist attack on a village, Central Camp, AMP. 11 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 08 10",
    title: "GoSL opens supply route from Vavuniya to Wanni",
    description: "Opening of supply route from VNA to WANNI.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 11",
    title: "LTTE attack Muslim village",
    description:
      '"Group of LTTE attacked the Muslim persons in Divisions 03 and 06 ERAVUR, Batticoloa 173 x Civilians were killed and 20 x Civilians were wounded.."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1983 08 12",
    title: "LTTE kills UNP Chief Organizer Jaffna",
    description:
      "Mr. AG RAJASOORIYAR – UNP Chief Organiser for JAFFNA TOWN. Killed by LTTE",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 12",
    title: "LTTE attack Muslim village",
    description:
      '"Terrorists attacked Muslim civilians working in a paddy field in Veerachcholai, AMP. 04 x Civilians were killed and 10 x Civilians were wounded."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 08 12",
    title: "LTTE attack Hindu festival",
    description:
      '"A group of terrorists fired towards civilians at a KOVIL FESTIVAL in ERAVUR (BCO). 02 x Civ KIA, 05 x Civ WIA."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 13",
    title: "LTTE kill Muslim fishermen",
    description:
      '"06 Muslim fishermen were killed and burried at GUNGAI village in Muttur, Trincomalee. 06 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 13",
    title: "LTTE attack villages in Vavuniya",
    description:
      '"A Muslim village adjoining a Sinhala village,  Avaranthalava in VNA was attacked. 10 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 08 13",
    title: "LTTE ambushes lorry",
    description:
      "Terrorists ambushed a Lorry travelling from NEGOMBO to KOKKUVIL at Pulmoddai. 14 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 13",
    title: "India bans pro-LTTE group",
    description:
      '"The Student Pro – LTTE Line taken by the TAMILAR DESIYA IYAKKAM (Tamil Nationalist Movement) the State Government today banned the outfit under the Criminal Law Amendment Act, 1908. (Internet Report – 13/08/2002)"',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 08 14",
    title: "LTTE attack Arantalawa",
    description:
      '"Terrorists fired at villagers in Aranthalawa, Ampara. 07 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 08 14",
    title: "LTTE assassinates Minister Jayaraj Fernandopulle",
    description:
      '"S/L Aviation Minister has had face to face talks with separatist Tamil Tiger rebels in the first such encounter in 11 years, officials said that JEYARAJ FERNANDOPULLAI had the rare meeting with Regional Leader of the separately Liberation Tiger of Tamil Eelam (LTTE) on Monday during a Roman Catholic pilgrimage to the Island embattled. (Tamil Canadian)    "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 14",
    title: "OSLO Meeting with GoSL and LTTE",
    description:
      '"A meeting was held between Minister Economic Reforms, Science and Technology and Deputy Minister for Policy Development and Implementation MILINDA MORAGODA and Chief Negotiator for the LTTE ANTON BALALSINGHAM in OSLO. The meeting took place in the presence of Norwegian State Secretary VIDAR HELGESAN Peace Envoy ERIK SOLHEIM Norwegian Ambassador to Sri Lanka JON WESTPORG, Advisor LISA GOLDEN Director General of the Peace Secretariat BERNARD GUNATHILAKE. The Head of SLMM TROND FURYHOVDE was also presents."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 14",
    title: "Sri Laka Officials meet LTTE",
    description:
      '"Meeting between Senior Officers of the SF Police with KARUNA, LTTE Military Leader, Eastern Province at VAVUNATIVU Regional Secretariat."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 15",
    title: "GoSL aircraft crashes",
    description:
      '"A Y-8 type Aircraft whilst on a test flight crashed near THUDUGALA, KADJUDUWA (DODANGODA) in KALUTHRA District. 03 x Offrs & 02 x Airmen were killed. "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 08 16",
    title: "LTTE kills civilians",
    description:
      "A bomb was exploded by LTTE in TCO Town. 04 x Civilians were killed. 19 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 08 16",
    title: "LTTE suicide",
    description:
      "LTTE suicide cadre having taken cover behind a trishaw had exploded himself in front of a jeep injuring the Inlelligence Offcer of 211 Brigade LT WAMT BALASURIYA MI and his team.The Int Officer succumbed to his injuries at General Hospital Colombo on 17/08/2000. (VNA TOWN)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1989 08 17",
    title: "LTTTE kills civilians",
    description:
      '"IED explosion in NOCHCHIKULAM, VNA. 08 x Civilians were killed and 04 were injured."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 08 17",
    title: "LTTE suicide",
    description:
      "VNA TOWN - LTTE suicide cadre having taken cover behind a three wheeler and exploded him self in front of the jeep with the INT Officer of 211 BDE and his team.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 08 18",
    title: "LTTE attack Sinhala village in Trincomalee",
    description:
      '"Armed terrorists fired at Sinhala villagers in Namalwatta, TCO. 07 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1987 08 18",
    title: "Attack on SL Parliament",
    description: '"Explosion in the Parliament at JAYAWARDANAPURA, KOTTE."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 18",
    title: "LTTE sea tigers",
    description:
      "LTTE sea movement consisting of 04 x LTTE boats was carried out today. The movement commenced at 0830 hours from MULLAITIVU and beached at VAKARAI at 1345 hours. SLN units monitored the movement through out the passage.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1994 08 19",
    title: "President Chandrika Bandaraaike comes to power",
    description:
      "President Chandrika Bandaranayake Kumaranathunga  came to power and invited LTTE for Peace Talk.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 08 22",
    title: "Meeting with LTTE's Tamilselvan by families of missing soldiers",
    description:
      "Member of the Association for Families of Servicemen missing in action met a gp of LTTE members led by Political Ldr THAMIL SELVAN at LTTE Hq in VNA on 14/08/2001.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 08 22",
    title: "LTTE explode claymore",
    description:
      "Terrorists exploded a claymore mine targeting CTB bus (61-2910) proceeding from TRINCOMALEE to ERRAKKANDY. (Nilaveli)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 22",
    title: "The controversial Millennium City debacle",
    description:
      "The Former Appeal Court Judge DHARMASIRI JAYAWICKRAMA has been appointed to probe into how the police came to know about the existence of the safe house in Millennium City.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 08 23",
    title: "LTTE explode bomb",
    description:
      "Two small time bombs planted by LTTE terrorists simultaneously exploded in two bunkers located close to the ALASWATTA brigade on NILAWELI Road. (Uppuveli)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 23",
    title: "Post-Cease Fire Meetings by LTTE",
    description:
      '"A meeting had been held at NAVANTHURAI Central Collage by the LTTE in order to discuss ways and means to obtain private houses, which are occupied by SF in NAVANTHURAI area. The meeting was attended by leading LTTE cadres ILAMPARIDI, MANALAN and EESWARAN."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 24",
    title: "Post-CFA Meetings between GoSL & LTTE",
    description:
      '"The GoSL announced a four-member team to represent the GoSL at the talks to be held in THAILAND in September with the LTTE. The team comprises Minister of Enterprise Development, Industrial Policy Investment Promotion and Minister of Constitutional Affairs, Professor G L PEIRIS, Minister of Economic Reforms Science and Technology Mr. MILINDA MORAGODA Sri Lanka Muslim Congress Leader, Minister of Ports and Minister of Muslim Affairs Mr. RAUF HAKEEM and Director General of Secretarial for Co-ordinating Peace Process Mr. BERNARD GUNATHILAKE. "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 24",
    title: "First Peace Talks in Thaland",
    description:
      "Norway Govt. announced that the date for the First Round of Peace Talks in THAILAND is 16th to 18th Sep 2002.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 08 25",
    title: "LTTE attack village in Polonnaruwa",
    description:
      '"Armed terrorists killed civilians by cutting their necks at Marawila, PLN. 11 x Civilians were killed."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 25",
    title: "LTTE key leaderies of cancer",
    description:
      '"LTTE leading cadre RAJU @ KUVILAN @ AMBALAWANA THEMINATHAN, who had been I/C of the Artillery Wing of the LTTE was died due to a Cancer."',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1993 08 29",
    title: "LTTE sea tiger attack",
    description:
      '"A Naval Craft  (Israel Super Dovra – P 464) was rammed by a Terrorist Suicide Boat laden with explosives, thus causing heavy damages in PPD.  "',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 08 29",
    title: "LTTE sea tiger attack",
    description:
      'The Ship "IRISH MOANA" was attacked by the LTTE, while anchored. (North of MLT)',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 08 29",
    title: "LTTE sea tiger attack",
    description:
      "The Argentinean Ship MV ATHNNEA was slightly damaged due to LTTE explosion. (TCO)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 08 29",
    title: "LTTE & SLMM",
    description:
      '"The LTTE Leadership in BATTICALOA had requested through the SLMM BATTICALOA permission to move LTTE political cadre from BATTICALOA to VAVUNIYA along the Main Supply Route through Government controlled areas on AM 30 August 2002. After intimating the matter to the Commander of the Army they were escorted from BATTICALOA to VAVUNIYA. The details of the political cadre who were escorted are as follows. SITHAMBARAPILLAI GOPALAN (VISHU) LTTE Local Area Leader, RAJAN SATHYAMOORTHI LTTE Local Co-ordinator, SIVASANKAR Bodyguard, NARAYANAPILLAI THAMIL SELVAM Driver."',
    images: [],
    videos: [],
    links: [],
  },

  {
    date: "1983 09 01",
    title: "UNP Politicians Killed by LTTE",
    description:
      "Mr. MALA RAMACHANDRAN – UNP Member of Municipal Council, THAMVAIKERNI, Batticoloa - Killed by LTTE",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 09 01",
    title: "TULF politicians killed by LTTE",
    description:
      "Mr. K THURAIRATHINAM - TULF MP for POINT PEDRO was shot dead at Pudukudurippu, Jaffna",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1992 09 01",
    title: "LTTE attacks Sinhala villges",
    description:
      "LTTE launched bomb attack, Saindamadu in AMPARA.  22 x Civilians killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 09 01",
    title: "SL Army attack LTTE",
    description: "OP Thunder Strike was launched in JAFFNA.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 09 01",
    title: "LTTE attacks passenger bus",
    description:
      "Armed LTTE cadres attacked a passenger bus as Aranthalawa, which had been plying between Ampara and Kandy. 11 x civilian passengers were killed and 31 were injured.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1997 09 01",
    title: "LTTE attack passenger ship",
    description:
      "Terrorists attacked a Foreign Ship ‘MV CORDIALITY’ an anchor off  PULMODDAI. It is believed 90 persons were on board including 05 Army personnel, 31 crew members and 54 minor workers. During the confrontation with the reinforcement troops, 02 x soldiers killed, 04 x injured and 05 x were missing.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 01",
    title: "LTTE attack Pooneryn Camp",
    description:
      "Terrorists launched a major attack on Security Forces Complex at KILINOCHCHI/POONERYN. Troops killed 689 x terrorists. Troops suffered 100 deaths, 706 x wounded and 03 were missing.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 01",
    title: "LTTE attack train",
    description:
      "A bomb that had been planted along the railway station road. ERAVUR exploded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 01",
    title: "LTTE attack",
    description:
      "A tractor carrying Air Force troops was caught in an anti-tank mine explosion 16 x Airmen KIA at VELANKULAM in VAVUNIYA. 16 x KIA (Airmen).",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 01",
    title: "Peace Talks Thailand",
    description: "First round of Peace Talks in THAILAND.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 02",
    title: "LTTE killed",
    description:
      "Tamil Eelam Mil Ldr RANJAN MANIKKADASAN, VAVUNIYA (PLOTE) Deputy Minister Leader ILLANGA and a member VINOTHAN were killed and member THARAN was injured due to a claymore mine explosion at LUCKY HOUSE, Vavuniya",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 09 03",
    title: "TULF politicians killed by LTTE",
    description:
      "Mr. K RAJALINGAM – TULF, MP for UDUPPIDDI, JFN was shot dead at UDUPPIDDI, JFN.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 09 03",
    title: "TULF politicians killed by LTTE",
    description:
      "Mr. V. DHARMALINGAM - TULF MP for MANIPAI, JFN. (Father of Mr. D. SIDDHARTHAN (PLOTE Leader) was shot dead.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 09 03",
    title: "TULF politicians killed by LTTE",
    description:
      "Mr. K ALALASUNDARAM  - TULF, MP for KOPAY was shot dead at THINNAVELI, JFN.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1994 09 03",
    title: "TELO killed by LTTE",
    description:
      "Mr. T. JAYARAJAN - Deputy Leader of TELO/Chairman of the Provincial Council in Batticaloa was shot dead CHENKALADY, BCO.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 03",
    title: "SL Army attack LTTE",
    description:
      "OP RIVIKIRANA Stage I, was launched from general area JAFFNA.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 03",
    title: "Peace Talks UK",
    description:
      "SLMC Leader RAUF HAKEEM met Head of the Negotiating Team of the LTTE Dr. ANTON BALASINGHAM at the Norwegian Ambassadorial residence in London.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 04",
    title: "Ban on LTTE lifted",
    description: "Ban on LTTE lifted.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2023 09 05",
    title: "No Crimes Reported Today",
    description: "",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2023 09 06",
    title: "No Crimes Reported Today",
    description: "",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1978 09 07",
    title: "LTTE blast airline",
    description: "AVRO Air Craft Belonging to Air Ceylon was blasted.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1997 09 08",
    title: "LTTE kill civilians",
    description:
      "Civilians were killed in the cross fire during on LTTE on KALANAPURA Army Detachment.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 09",
    title: "LTTE kill civilians",
    description:
      "Terrorists attacked farmers who were travelling on a tractor at Uhana, AMP. 07 x Civilians were killed and 02 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1997 09 09",
    title: "LTTE attack ship",
    description:
      'A Foreign Ship "MV CORDIALITY" at anchorare Off PULMODAI was attacked by the terrorists. It is believed 90 persons were  on board including  05 Army personnel, 31 Crew  members and  54 minor workers. Terrorists have  set fire to the Engine Room 04 FGDs deployed by the Army confronted terrorists boats and 01 FGD returned ashore. Whereabouts  of other 03 Army FGDs are not  known. 03 Navy FACs and 02 FGBs on patrol were also rushed to the  location for assistance and confronted fleeing LTTE boats Monitored LTTE transmissions reveals heavy losses and damages to LTTE cadres and boats.',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 09",
    title: "SL Army attack LTTE",
    description: "OP RIVIKIRANA Stage II, was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 09 10",
    title: "LTTE kill civilians",
    description:
      "Terrorist attack on a Sinhala settlement at 16th Colony in AMP. 07 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1992 09 10",
    title: "LTTE kill civilians",
    description:
      "Ferry at KILIVEDDY POINT, TCO was blasted. 06 x Civ were killed and 02 x Civ were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 10",
    title: "LTTE attack soldiers",
    description:
      "02 x soldiers travelling in a trishaw from SELVANAGAR Detachment to KALLAR for an administrative matter got caught to a terrorist claymore mine explosion. (Pahala Thoppur)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 11",
    title: "TULF politicians killed by LTTE",
    description:
      "Mr. PONNADURAI SIVAPALAN – TULF/ Mayor of MC, JAFFNA. (Time Bomb Explosion) (Near the Nallur Kovil, JFN) killed by LTTE",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 11",
    title: "LTTE attack Municipal",
    description:
      "An explosion took place in the Municipal Commissioner's Office closer to NALLUR KOVIL, while a meeting was in progress.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 11",
    title: "LTTE kill Mayor of Jaffna",
    description:
      "Killing of JAFFNA Mayor Mr. SIVAPALAN, JAFFNA Brig Comd and SP JAFFNA.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 11",
    title: "SL Army attack LTTE",
    description: "Phase V of Op RANAGOSA was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 09 12",
    title: "LTTE attack bus",
    description:
      "Terrorists attacked to the SLTB Bus plying from AMPARA to KANDY. (Aranthalawa)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 12",
    title: "LTTE kidnap soldier",
    description:
      "A soldier named PTE WIJESINGHE DM who had taken away the T 56 Weapon with a Magazine and 30 x 7.62 rounds of Ammunition belonging to another soldier and vacated the camp whilst on duty had been arrested by the LTTE in the uncleared area, MARUTHAMADU, MNR. Later it was revealed that the LTTE had released him at THALIMANNAR to a COLOMBO bound bus on 30/10/2002.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 13",
    title: "LTTE kill civilians",
    description:
      "SF recovered 07 mutilated bodies of Muslim villagers who were hacked to death at South of Poonani, in AMP. 07 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 13",
    title: "LTTE explode claymore mine",
    description:
      "Terrorists exploded a claymore mine placed on a tree targeting Air Force bus plying from BCO to VALIKAMAM.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2023 09 14",
    title: "No Crimes Reported Today",
    description: "",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 15",
    title: "LTTE suicide",
    description:
      "A Male Suicide Bomber who was had been waiting to target a VVIP, exploded himself when a Police Constable searched him and suspicion in front of the Eye Hospital Gate, Deans Road.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 16",
    title: "LTTE explode claymore mine",
    description:
      "Terrorist exploded a claymore mine in Palampiddy on a truck carrying soldiers.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 16",
    title: "M H M Ashroff dies",
    description:
      "A MI 17 helicopter was air borne transporting Hon. Min. of Ports Development, Rehabilitation and Reconstruction Mr. M H M ASHRAFF with his party members from CBO to AMP. The helicopter had hit KUMARAKANDA HILL. All passengers including crew members were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 09 16",
    title: "LTTE attack ship",
    description:
      'Naval  convoy with the ship "MV PRIDE OF SOUTH" was attacked by the LTTE Black Sea Tigers while carrying 1200 troops from TRINCOMALEE to KANKESANTHURAI. Two Naval Fast Attack Crafts were damaged due to the incident. Naval troops repulsed the attack with the help of the Air Force. (PPD)',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 16",
    title: "LTTE commemorations",
    description:
      "LTTE cadres in two buses had entered the KACHCHERI Premises, JAFFNA and hoisted the LTTE flag to commemorate 15th Death Anniversary of THILEEPAN. LTTE cadres had also instructed KACHCHERI Officials to hoist the LTTE flag daily from 0930 hours to 1700 hours from 17/09/2002 to 26/09/2002.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 16",
    title: "Peace Talks Thailand",
    description:
      "The first round of Peace Talk IV between the Government of Sri Lanka and the LTTE was held at the SATTAHIP Naval Base in CHONBURI Province, THAILAND.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1986 09 17",
    title: "LTTE kill civilians",
    description:
      "Terrorists entered the Block 04 of the Suger Corporation, KANTALAI, TCO and attacked the villagers. 10 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1986 09 17",
    title: "LTTE kill civilians",
    description:
      "Armed terrorists fired at Sinhala villagers in Wadigawewa, TCO. 12 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 17",
    title: "SL Army attack LTTE",
    description: "Op KINIHIRA Stage I, was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 17",
    title: "War Heroes capture Chavakachcheri",
    description:
      "Recaptured the CHAVAKACHCHERI area including CHAVAKACHCHERI Town.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 18",
    title: "LTTE attack Sinhala village",
    description:
      "Attack on Galapitagala, Badirekka, Borapola Sinhala villages by the LTTE.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 19",
    title: "LTTE kill civilians",
    description:
      "Terrorists attacked the VELLAMUNAI Fishing village in Puttalam. 23 x Civilians were killed and 02 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1991 09 19",
    title: "LTTE kill civilians",
    description:
      "Terrorists attacked a Muslim village, Palliyagodella, Medirigiriya in Polonnaruwa. 13 x Civilians were killed and 06 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1994 09 19",
    title: "LTTE attack ship",
    description:
      'SLN Ship "SAGARAWARDANA" was attacked by terrorists ramming 02 x Explosive Laden Boats off Thalpadu.',
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 19",
    title: "LTTE kill soldiers",
    description:
      "An Army vehicle carrying troops was ambushed by terrorists with claymore mines RPGs and small arms fire between KENNIYA and VELVARIKULAM Navy Detachment on TCO-ANP MSR. 02 x Offrs and 22 x soldiers were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 19",
    title: "LTTE claymore",
    description:
      "Army truck proceeding to VILGAMVEHERA from TCO with 25 x solders including 02 x Offrs, was attacked by terrorists. 02 x Offrs KIA and 21 x soldiers.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 19",
    title: "LTTE claymore",
    description:
      "An Army vehicle carrying troops was ambushed by terrorists with Claymore mines, Rocket Propelled Grenades and Small Arms fire. (Kinniya)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 19",
    title: "US-UK in Peace Talks",
    description:
      "The United States and Britain hailed the successful outcome of the first round of talks between the Govt. and the LTTE.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 19",
    title: "LTTE kidnap civilian",
    description:
      "LTTE arrested a bus driver from KALUTARA for running over and eight-year-old child in JAFFNA.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 09 20",
    title: "LTTE-GOSL ceasefire",
    description:
      "The Sri Lanka Govt. and the LTTE have agreed to two temporary ceasefire to allow children in the North Eastern War Zone to the recented against Polio. This will be observed an 19th and 20th Oct 2001 in response to the request made by UNICEF.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 21",
    title: "LTTE kill civilians",
    description:
      "Terrorists attacked the village, Pudukudirippu in AMP. 15 x Civilians were killed. And 11 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1991 09 22",
    title: "LTTE attack ship",
    description:
      "Naval Craft P 143 was destroyed by a terrorist activated Explosive Device in ANALATIVU, JFN. 06 x SLN persons were killed, 01 x killed and 01 x missing.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 22",
    title: "LTTE attack bus",
    description:
      "A bomb exploded in a Peoplised Transport Service bus belongs to Negombo.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 22",
    title: "LTTE attack bus",
    description:
      "A bomb exploded in a private coach near the Kirmethiyawa Temple Junction.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2023 09 23",
    title: "No Crimes Reported Today",
    description: "",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 24",
    title: "LTTE attack houses",
    description:
      "Terrorists set fire to 05 houses at Gajabapura, WLO. 04 x Civilians were killed.)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 09 25",
    title: "LTTE claymore",
    description:
      "A SF truck plying along JAFFNA - KANDY Road was caught in a terrorist claymore mine explosion. (Kaithadi)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 09 25",
    title: "LTTE abducts soldier",
    description:
      "Seven soldiers of 221 Brigade HQ, an Army Truck and a T-56 weapon had been abducted away by the LTTE while they were collecting sand in VILGAMVEHERA, TRINCOMALEE. Subsequently one soldier whom son is sick was released on 01/10/2002 and rests were released on 09/10/2002.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 09 26",
    title: "LTTE Thileepan",
    description: "LTTE Jaffna leader Theleepan started fast unto death",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 26",
    title: "LTTE attack Jaffna Fort",
    description: "Attack on Jaffna Fort Army Camp.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 26",
    title: "LTTE attack Kilinochchi",
    description: "Attack on Kilinochchi Army Camp. (Oyatha Alaigal- II)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 09 26",
    title: "LTTE bus attack",
    description:
      "A time bomb exploded in a private coach near the 02nd Mile Post, Badulla.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 26",
    title: "SL Army attack LTTE",
    description: "Started Unceasing Wave IV.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 26",
    title: "SL Army attack LTTE",
    description: "OP KINIHIRA Stage II was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 26",
    title: "LTTE attack army",
    description:
      "Seven bunkers were vacated by the troops in the Forward Defence Line due to heavy volume of terrorist artillery, mortars, Rocket Propelled Grenades and small arms fire. (JFN)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 26",
    title: "LTTE claymore",
    description:
      "Exploded a claymore mine with small arms fire at a Guard Battalion tractor, which was carrying troops returning after leave at KILALY. 09 x solders were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 27",
    title: "LTTE attack army",
    description:
      "Terrorists launched massive attacks simultaneously on Southern defence lines and Western defence lines. Troops (7 SLSR) deployed on the line in the South West flank observed a concentration of terrorists ahead of the forward defence lines and engaged with small arms, mortar and artillery fire.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 28",
    title: "LTTE ethnic cleansing of Muslims",
    description:
      "Ethnic Muslims were chased away from Jaffna Peninsula and Mannar.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 28",
    title: "LTTE attack Kilinochchi",
    description: "Attack on KILINOCHCHI Complex.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1982 09 29",
    title: "LTTE attack ship",
    description: "Attack on Navy Convoy in Jaffna by LTTE",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 29",
    title: "SL Army attack LTTE",
    description: "Capture of MANKULAM by Op Jayasikuru troops.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 09 29",
    title: "LTTE attack plane",
    description:
      "The LION AIR has suspended services to RATMALANA from JAFFNA after the loss of a Russian Built AN -24 Aircraft carrying 48 civilians and a six member crew including  four foreigners. The SLAF scotched rumours and speculations that the aircraft had been hijacked by the LTTE.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 29",
    title: "SL Army attack LTTE",
    description: "OP KINIHIRA Stage III, was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 09 30",
    title: "LTTE kill civilians",
    description:
      "Terrorists hacked the civilians to death in Mahaoya, AMP. 09 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 09 30",
    title: "LTTE attack Minister",
    description:
      "The Hon. Dy Minister of Post and Telecommunications Mr. MLAM HISBULLAH and his supporters were fired upon by terrorists with small arms, when they were proceeding to Batticoloa  after an election meeting. 03 x PCs of the Minister's Escort were injured and are being treated at the Batticoloa Hospital.",
    images: [],
    videos: [],
    links: [],
  },

  {
    date: "1990 10 01",
    title: "LTTE attack villag",
    description:
      "Terrorist attack on Sinhala village, PERUVALTHALAWA in AMP. 09 x Civs were killed and 03 x Civs were wounded.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1992 10 01",
    title: "LTTE attack villag",
    description:
      "Terrorist attack on KONWEWA village in WLO. 15 x Civs were killed and 09 x Civs were wounded. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1981 10 02",
    title: "LTTE kill UNP leader",
    description:
      "Mr. SUBRAMANIAM, UNP Organizer for KLN was murdered at PARANTHAN.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 02",
    title: "LTTE attack villag",
    description:
      "Terrorists attacked the village, WAHALKADA in ANP. 04 x Civs were killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 02",
    title: "LTTE suicide",
    description:
      "A Navy Sea Convoy sailing from KANKASANTHURAI was attacked by a large number of terrorists in the sea off MLT. Due to counter attack 01x Suicide Boat was destroyed. 01 + 15 Army pers were killed, 50 x Army pers were wounded, 04 x SLN pers were killed and 29 x SLN pers were wounded.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 02",
    title: "USA declares LTTE Terrorists",
    description:
      "The UNITED STATES re-designated the LTTE as a FOREIGN TERRORIST ORGANISATION (FTO).",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 02",
    title: "LTTE child soldiers",
    description:
      "The LTTE demobilised handed over 49 under–aged cadres. (27 girls and 22 boys) to UNICEF Representatives in KLN to be included their programme of reintegrating them in society. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 03",
    title: "LTTE child soldiers",
    description:
      "The first of three 'TRANSIT HOMES' for the under aged LTTE cadres (child Sldrs) was opened in KLN. The home has been established under the Action Plan for Children Affected by War developed in accordance with the agreement reached between the GOSL and the LTTE at the OSLO talks. The project funded by the UN Agency.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2006 10 03",
    title: "NGOs banned",
    description:
      "Undermentioned NGOs that operated in the North and East were banned. Visas of the staff of the NGOs also cancelled. a. C M F FRANCE b. M S F SPAIN c. M B M FRANCE d. Doctor's of the World USA e. Solidarities f. Medicos de MUNDO. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 05",
    title: "LTTE suicide",
    description:
      "KUMARAPPA (SS LT COL), PULENDRAN and 10 Other LTTE cadres committed suicide in the SF custody at the PLY Airport. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 06",
    title: "LTTE train attack",
    description:
      "Batticoloa district - Valaichchenai massacre: 40 Sinhalese passengers in the night-mail train from Batticaloa were killed by LTTE cadres who stopped the train.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 06",
    title: "LTTE attack villag",
    description:
      "Batticoloa - Tharavi massacre: Tharavi, a majority Sinhalese village, was stormed by over 100 LTTE cadres armed with swords, machetes and small arms, who killed 25 civilians, mostly women and children including infants and pregnant mothers.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 06",
    title: "LTTE attack villag",
    description:
      "Trincomalee district - Sagarapura massacre: A house-to-house massacre was carried out by LTTE cadres, clubbing children to death in front of their parents, and hacking the adults to death using sharp weapons. 27 Sinhalese villagers were shot dead. After looting their victims' homes, these were set alight, burning the wounded victims alive. Another six villagers survived the attack with serious cut wounds / 57 houses of sinhalese attacked",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 06",
    title: "LTTE attack villag",
    description:
      "Batticaloa: 18 Sinhalese civilians were shot dead by LTTE cadres.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 06",
    title: "General Elections",
    description: "General Elections.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 06",
    title: "LTTE kill Janaka Perera",
    description:
      "Maj. Gen. K JANAKA C PERERA RWP RSP VSV USP rcds psc (Retd), Leader of the Opposition, North Central Provincial Council was assassinated in a Suicide Bomb attack targeting him at ANURADHAPURA TOWN. 27 x Civ were killed and 84 x Civ were wounded. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 07",
    title: "LTTE bus attack",
    description:
      "Ampara District - Pottuvil Monargala Road massacre: 30 Sinhalese passengers travelling by bus were shot dead by LTTE cadres, who also killed five motorcyclists travelling along the same route.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1997 10 08",
    title: "LTTE suicide",
    description:
      'A group of LTTE Suicide cadres activated a Vechile Bomb (an Explosive laden "TATA" Lorry) at the Car Park of the GALADARI HOTEL, close proximity to the World Trade Centre, FORT, COLOMBO. ',
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 08",
    title: "Karuna Amman",
    description:
      "The Leader of the THAMIL MAKKAL VIDUTHALAI PULIGAL VINAYAGAMOORTHI MURALIDARAN @ KARUNA @ KARUNA AMMAN has given oaths as a MP under  President MAHINDA RAJAPAKSE's Govt. from the UPFA National List.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1994 10 09",
    title: "LTTE attack ship",
    description:
      "Terrorists attack on MV OCEAN Trader Vessel in VETTILAIKERNI SEA, KLN.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 09",
    title: "LTTE suicide attempt",
    description: "Bomb blast at MP Mr. D DEVANANDA's Residence at WELLAWATTA.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2002 10 09",
    title: "LTTE attack camps",
    description:
      "When suspected LTTE cadres try to enter into the Billets of the STF Camp at KANCHANKUDAH, AMP destroying the Shrine Room and Rest Room, STF pers opened fire killing 07 x suspected LTTE cadres and injuring 12 x others. 05 x STF pers sustained injuries.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 09",
    title: "LTTE suicide",
    description:
      "A LTTE Suicide Bomber (Female) exploded herself targeting the motorcade of Hon. Minister MAITHRIPALA SIRISENA, Minister of Agricultural Development and Agrarian Service Development and General Secretary of SLFP at PIRIVENA JUNCTION,  BORALESGAMUWA. The Minister had a narrow escape in this Abortive Attempt. Due to the explosion, 02 x Civ were killed and 06 x Civ were wounded. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1964 10 10",
    title: "Srima-Shashtri Pact",
    description: "SIRIMA SHASTHRI AGREEMENT.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 10",
    title: "LTTE attack villag",
    description:
      " Trincomalee district - Ganthalawa massacre: Gantalawa hamlet in Kantala was attacked and 9-10 civilians murdered with three wounded by LTTE cadres armed with swords, machetes and small arms.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 10",
    title: "1st LTTE female killed",
    description: "MALATHI, a First Woman Tiger was killed in an IPKF attack. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 10",
    title: "LTTE attack SLAF & IPKF",
    description:
      "LTTE broke Ceasefire Agreement and started attack on SLAF and IPKF. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 10",
    title: "PA forms Govt",
    description:
      "PEOPLE'S ALLIANCE (PA) formed the Govt. under Mrs. CHANDRIKA BANDARANAIKE.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 11",
    title: "LTTE kills Muslims",
    description:
      "Terrorists shot and hacked to death 09 Muslims who were collecting fire woods near KIRANKOVE JUNGLE, ARUGAMBE, AMP.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 11",
    title: "APC conference",
    description:
      "All Party Conference (APC) held at the President's Secretariat. 14 Political Parties participated.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 11",
    title: "President MR calls on LTTE to lay down arms",
    description:
      "HE the President MR called on the LTTE to lay down arms and enter the Democratic Political Main Stream, addressing the All Party Conference at the President's Secretariat.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1994 10 13",
    title: "Peace Talks",
    description:
      "PEACE TALKS (3) between the GoSL and the LTTE in JAFFNA. PHASE II - 13/01/1995, PHASE III - 17/03/1995 and PHASE IV - 28/03/1995 were held in JAFFNA.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 14",
    title: "LTTE suicide",
    description:
      "An LTTE Suicide Bomber exploded himself at F R SENENAYAKE MAWATHA, in front of the TOWN HALL Building. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 14",
    title: "Pongu Thamil",
    description:
      "PONGU THAMIL Festival MANNAR. The Army had complained to the SLMM regarding participation of 1300 students and the hoisting of LTTE flag at the festival..",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1981 10 15",
    title: "LTTE kill army",
    description:
      "S/63480 L/Cpl HEWAWASAM,  S/8232 Pnr TISSERA HHD and Civ S R FERNANDO were shot dead at KKS Road, JFN TOWN. 01 x .303 Rifle was removed. Led by CHARLES ANTHONY @ SEELAN. (It was the first attack on the SF persons by the LTTE)",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 15",
    title: "LTTE attack village",
    description:
      "Trincomalee district - Ella Kantalai massacre: Ella Kantalai, a majority Sinhalese village, was attacked by LTTE cadres armed with swords, machetes and small arms, killing 14 civilians including women and children.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1992 10 15",
    title: "LTTE attack village",
    description:
      "Terrorist attack on a PALLIYAGODELLA and AHAMADPURA villages in PLN. 146 x Civs were killed. 83 x Civs were wounded ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 15",
    title: "LTTE suicide",
    description:
      "An LTTE Suicide Bomber exploded herself at an Election Meeting held by the PA in MEDAWACHCHIYA TOWN. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 15",
    title: "LTTE child soldiers",
    description:
      "The LTTE released 13 x Child Sldrs recruited by them in BATTICALOA.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 16",
    title: "LTTE bus attack",
    description:
      "Trincomalee district - Pulimodai massacre: A private bus was stopped by LTTE cadres, who removed the Sinhalese people killing 11 including three policemen.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 16",
    title: "LTTE attack camps",
    description:
      "JFN FORT SF CAMP was withdrawn by the troops subsequent to the massive attack by the LTTE on the camp on 14 Jun 1990.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 16",
    title: "SLMM role",
    description:
      'The SLN accused the SLMM of jeopardising their operation to round up a LTTE Arms Ship when their operation was made unsuccessful, as the information was leaked to the LTTE. It had been later revealed that a LTTE cadre onboard a LTTE Vessel named "AGASTHI" committed suicide while in an INDONESIAN PORT. It is also reported that the vessel was loaded with long range artillery shells, mortars and surface to surface missiles among other items of military hardware. It is beloved that information regarding the SLN operation to track down the LTTE ship had been leaked to the LTTE by the SLMM which resulted in the ship moving away from SL waters which was subsequently arrested by the INDONESIAN Govt.',
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2006 10 16",
    title: "LTTE suicide",
    description:
      "A LTTE Suicide cadre driving a Truck ISUZU Canter laden with explosives rammed into Navy Buses boarded with Naval personnel that were parked at TRANSIT POINT at DIGAMPATHANA, HABARANA. Due to the killing 96 x Sailors, injuring x 115 Saliors and missing x 05 Sailors 01x Terr killed.  ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2006 10 16",
    title: "NE Demerged",
    description:
      "The Supreme Court issues de-merge order for the Northern & Eastern Provinces. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1983 10 17",
    title: "LTTE theft",
    description:
      "Robbery of 89 x Shot Guns and cash Rs. 12000/= from the BCO, KACHCHERI.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 17",
    title: "Army Ops",
    description: "Commencement of SF OP RIVIRESA.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 17",
    title: "Ltte suicide",
    description:
      "LTTE launched an Underwater Suicidal Attack on a Naval Auxiliary Vessel A 512, which was anchored in TRINCOMALEE HARBOUR and destroyed it completely. 02 + 05 SLN pers were killed, 06 x SLN pers were wounded and 02 x Civs were killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2006 10 18",
    title: "LTTE suicide",
    description:
      "Sea Tiger Suicide cadres launched an attack on the Naval Base “DAKSHINA”, GALLE disguised as fishing boats. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1987 10 19",
    title: "LTTE bus attack",
    description:
      "Batticoloa district - Kalkudah attack: A private bus transporting Tamil passengers was blown up by a LTTE land-mine, killing 40 persons and an IPKF soldier 24 civilians wounded",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 19",
    title: "LTTE attack camps",
    description:
      "Terrorists attacked FDL in NAGARKOVIL using artillery and mortar fire. Terrorists captured the strong points at NAGARKOVIL. One MI 24 Helicopter crash-landed due to terrorist fire. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 19",
    title: "LTTE bus attack",
    description:
      "An IED was exploded in a Bus parked at CENTRAL BUS STAND, PETTAH. No casualties were reported and 03 x CTB Buses were damaged due to the incident. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 20",
    title: "LTTE refinery attack",
    description:
      "LTTE launched an attack on the OIL REFINERY at KOLONNAWA and OIL INSTALLATION at ORUGODAWATTA. 05 x Sldrs were killed, 01 + 06 SLAF pers were killed, 03 x Police pers were killed, 01 x STF were killed, 01 x Civs were killed, 02 x Sldrs were wounded, 01 x SLAF per was wounded, 05 x Police pers were wounded, 26 x Civs were wounded and 02 x Oil Tanks were destroyed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 21",
    title: "LTTE attack village",
    description: "Terrorist attack on a MANGALAGAMA village.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 21",
    title: "LTTE attack village",
    description: "Terrorist attack on a MONARATHENNA village. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 21",
    title: "LTTE attack village",
    description:
      "Terrorists entered the area and attacked  the NORTH OF PADAVIYA, GALTHALAWA village.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 21",
    title: "LTTE child soldiers",
    description:
      "The LTTE released seven Child Sldrs in the TOWN OF AMPARA to be reunited with their parents.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2007 10 22",
    title: "LTTE attack camps",
    description:
      "Combined Ground and Air attack on the SLAF BASE, ANP by LTTE Black Tigers. 02 x Bombs (IED) were dropped by 02 x LTTE Light Aircraft. 13 x SLAF pers (04 + 09) were killed. 20 x SLAF pers were wounded. 01 x Sldr was killed. 02 x Sldrs were wounded.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 22",
    title: "LTTE suicide",
    description:
      'Sea Tiger Suicide Bombers on Three LTTE Suicide Craft attempted to blow up the Merchant Ships MERCS "NIMALAWA" and MERCS "RUHUNA" carrying essential relief items for Civs and Cement in the seas North of JAFFNA around 0510 a.m.. Two LTTE Suicide Crafts were completely destroyed and another was captured by the SLN. The Ship "NIMALAWA" was slightly damaged in its hull when the Suicide Craft exploded close to the Vessel. (Abortive attempt)',
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1981 10 23",
    title: "LTTE theft",
    description:
      "Armed robbery of Gold worth of Rs. 27.8 Million from the KILINOCHCHI, PEOPLE's BANK. 01 x Sldr killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 23",
    title: "LTTE attack village",
    description:
      "Approx. 30 LTTE cadres attacked the Chena Cultivators at KOKABE, THANTHIRIMALAI village in ANP. 10 x Civs were killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 23",
    title: "LTTE attack village",
    description: "Terrorist attack on a ETHIMALAI and KOTIYAGALA village.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 23",
    title: "LTTE suicide",
    description:
      "A Suicide attack on to a NAVAL DOCKYARD, TRINCOMALEE destroying/damaging passenger craft and gunboats. Subsequently, a MI - 21Helicopter crashed on to the Eastern Sea of Marble Beach whilst engaging hostile boats in KINNIYA Ferry.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 23",
    title: "CBK calls removal of Norwegian ",
    description:
      "HE the President CBK has formally requested the PM of the Royal Norwegian Govt. to take action to remove Maj Gen TRYGGVY TELLEFSEN, Head of the SLMM from his post. The most recent incident is that of giving information to the LTTE there by preventing the apprehention of an LTTE Ship suspected of smuggling arms to SRI LANKA.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1994 10 24",
    title: "Gamini Dissanayake assassinated",
    description:
      "Opposition Leader/UNP Presidential Election Candidate Mr. GAMINI DISSANAYAKE and six Politicians Mr. G B M PREMACHANDRA, MP/DUNF Leader, Mr. WEERASINGHE MALLIMARACHCHI, MP/Former Minister of Food & Co-op, Dr. GAMINI WIJESEKARA, Gen Secretary of the UNP, Mr. CHRISTY PERERA, UNP Provincil Counseller, Western Province, Mr. OSSIE ABEYGUNASEKERA, MP CBO Dist/ SLMP Leader were assassinated, whilst they were participating in the UNP Presidential Campaign meeting by a Suicide Bomber at GRANDPASS JUNCTION, COLOMBO. 54 x Civs were killed, 72 x Civs were wounded, 03 x Sldrs were wounded, 02 + 02 Police pers were killed and 08 x Police pers were wounded.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 25",
    title: "LTTE attack village",
    description:
      "About 40 Armed LTTE terrorists attacked the village, PANMEDAVACHCHIYA in WLO. 04 x Civs were killed and 02 x Civs were wounded.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2000 10 25",
    title: "LTTE attack village",
    description:
      "BINDUNUWEWA Rehabilitation Camp was attacked by the residents of the area due to provocations by the detainees. 22 x Detainees were killed while 16 x Others sustained injuries. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 26",
    title: "LTTE attack village",
    description: "Terrorist attack on a THAMMENNA, HALMILLEWA village. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 26",
    title: "LTTE attack village",
    description: "Terrorists attacked on a ALINCHIPOTHANA village.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1995 10 26",
    title: "LTTE assassinations",
    description:
      "Mr. ANTHONY THOMAS, Deputy Mayor, TELO member was shot dead at KALLADY, BCO.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1997 10 26",
    title: "USA bans LTTE",
    description: "U.S.A. Government banned the LTTE.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1982 10 27",
    title: "LTTE attack police",
    description:
      "CHAVAKACHCHERI Police Station was attacked.  03 x PCs were killed. Deceased are PC 6210 KARUNANANDAN, PC 6640 KANDIAH and PCD 15611 ,THILAKARATNE. 02 x Police pers were injured. (Led by CHARLES ANTHONY @ SEELAN, and he was shot in the leg in the incident). 02 x SMGs, 02 x Shot Guns, 01 x Revolver and Ammo were removed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 27",
    title: "LTTE attack village",
    description:
      "Terrorists fired at the villages at THANTHIRIMALAI, ANP. 05 x Civs were killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 28",
    title: "LTTE attack power station",
    description:
      "LTTE Air Attack on the KELANITISSA POWER STATION in GRANDPASS, COLOMBO. An LTTE Light Aircraft dropped 03 x Bombs into the premises of the Power Station. 01 x Civ was killed. The Main Building and some Turbines were damaged.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2008 10 28",
    title: "LTTE air attack",
    description:
      "LTTE Air attack on the AREA HW, MNR. An LTTE Light Aircraft dropped 03 x Bombs (IED) at the AREA HQ, MNR. 02 x Sldrs were wounded. 01 x SLAF per was wounded. ",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "1990 10 29",
    title: "LTTE attack village",
    description:
      "Terrorists fired at the villages at OLIKULAM in BCO. 03 x Civs were killed.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2006 10 30",
    title: "GENEVA TALKS",
    description: "GENEVA TALKS (2) at Varembe, GENEVA, SWITZERLAND.",
    links: [],
    images: [],
    videos: [],
  },
  {
    date: "2003 10 31",
    title: "LTTE ISGA Proposls",
    description:
      'LTTE handed over their alternative proposals for an Interim Administration to the GoSL. The LTTE proposals describes the Interim Administration as an "INTERIM SELF – GOVERNING AUTHORITY" (ISGA) for the North East of the Island of SRI LANKA. Norwegian Ambassador HANS BRATTSKAR after receiving the LTTE proposal from LTTE Political Wing Leader S P THAMIL SELVAN in KLN, handed over the document to the Govt\'s Chief Peace Negotiator, Minister, Prof. G L PEIRIS at VISUMPAYA, COLOMBO 02.',
    links: [],
    images: [],
    videos: [],
  },

  {
    date: "1984 11 01",
    title: "LTTE killing of soldiers",
    description: "Killing of 09 Soldiers in Jaffna by LTTE.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1987 11 01",
    title: "13th Amendment ",
    description:
      "13th Amendment to the Constitution provided for Provincial Councils.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 11 01",
    title: "LTTE attack villages",
    description:
      "Terrorists fired at SF Guard Point and the Helambawewa village in Weli Oya 10 x Civilians were killed and 01 x wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 11 01",
    title: "Sri Lanka Armed Forces",
    description:
      "Operation Royal Flash cleared and dominated Valikamam West of Jaffna Peninsula.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1996 11 01",
    title: "Sri Lanka Armed Forces",
    description:
      "Operation Rekawarana cleared the terrorists bases in the coastal belt of  Trincomalee.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 01",
    title: "LTTE - International Community",
    description:
      "LTTE Political Wing Leader S P THAMIL SELVAN met with Japanese Special Peace Envoy YASUSHI AKASHI soon after returning to the country after a month - long European tour.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 11 02",
    title: "LTTE attack villages",
    description: "Terrorists hacked villagers to death. (Siyabalanduwa)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 02",
    title: "LTTE SL Navy",
    description:
      "Sri Lanka Navy detected an LTTE boat with 06 cadres. On inspection several weapons including claymore bombs were found. The cadres were arrested.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 11 03",
    title: "LTTE attack villages",
    description:
      "Terrorists attacked the village, Bandarakubukwewa in ANP. 04 x Civilians were killed and 02 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1985 11 07",
    title: "LTTE attack villages",
    description:
      "Armed terrorists fired at Sinhala villagers in Namalwatta, TCO. 10 x Civilians were killed.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1984 11 08",
    title: "LTTE kill police",
    description:
      "Killing of 16 Police Officers by a Claymore attack in  Mannar.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 08",
    title: "LTTE kill TULF",
    description:
      "Mr. ASHLY NIMALANAYAGAM SAUNDRANAYAGAM, a TULF member elected to Parliament from BATTICALOA District was gunned down while he was travelling to BATTICALOA town to VALAICHCHENAI.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1987 11 09",
    title: "LTTE car bomb",
    description:
      "A Car Bomb explosion inside a vehicle opposite Zahira College, Maradana, CBO. 23  x Civilians were killed and 106 x Civilians were wounded. ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 11 09",
    title: "LTTE kill police",
    description:
      "LTTE cadre had made unsuccessful attempted on Police Offices who were coming in a jeep on the road between KALKUDAH - VALACHCHENAI. A bag containing a claymore mine mistake by exploded aiming himself. The cadre was died on the spot and injured to 02 Police Officers.  ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1993 11 11",
    title: "LTTE attack army camp",
    description:
      "LTTE attack on the SF Camp POONERYN, JFN. 08 + 225 Army killed, 17 + 544 Army wounded and 342 x Army missing.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 11 11",
    title: "LTTE attack army camp",
    description:
      "A LTTE suicide bomber who attempted to enter the Army Head Quarters premises through the main entrance was shot dead when he tried to run away from the guards at the Military Police Check Point.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1995 11 11",
    title: "LTTE suicide",
    description:
      "A Second Suicide Bomber who had been in the vicinity of AHQ detonated himself in close proximity to SLAVE ISLAND Railway Station.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 12",
    title: "Ceasefire agreement",
    description:
      "LTTE had disapproved the Security Forces movement to MODARAGAMMADU (MNR). Area Commander MANNAR, Commander 215 Brigade and the troops withdrew from the official visit with civil officials to MODARAGAMMADU (MNR), although the MoU facilitate the free movement of troops in the cleared area.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1987 11 13",
    title: "LTTE kill Muslim",
    description:
      "Mr. ABDUL MAJEED - MP for MUTHUR was shot dead at Muttur, Trincomalee",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1988 11 14",
    title: "LTTE attack buses",
    description:
      "Terrorists ambushed a Civil Bus at Peniketiyawa, Trincomalee. 28 x Civilians were killed. 02 x Civilians were wounded.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1997 11 14",
    title: "LTTE attack power station",
    description:
      "Terrorists blasted KELANITISSA Power House, Orugodawatta. 02 x Oil Tanks were damaged by Magnetic Explosive Devices. ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 14",
    title: "LTTE attack banks",
    description:
      "Terrorist fired at policemen who were on duty at KATTANKUDY Bank of Ceylon branch. A policeman was killed and two civilians were injured in the crossfire..",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 11 15",
    title: "LTTE suicide",
    description:
      "A LTTE Suicide Cadre exploded himself at Batticoloa Town, Near Siripala Building targeting Int Operatives belongs to Intel team, 233 brigade whilst they were on duty.  ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1992 11 16",
    title: "LTTE suicide",
    description:
      "A LTTE Suicide Killer on a motor cycle activated a device after moving alongside the vehicle of the Comd of the Navy Vice Admiral WWE Clancy FERNANDO at Galle Face, COLOMBO 03. 01 + 03 SLNavy pers killed incl the Comd.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1999 11 17",
    title: "LTTE attack army camp",
    description: "SF Fwd Mil Bases in North of Mannar had been attacked.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 11 17",
    title: "LTTE kill UNP MP",
    description:
      "Killed Mr. SINNATHAMBY RAJKUMAR – UNP Candidate for Batticaloa District – General Election 2001. (Batticoloa)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: " 11 18",
    title: "LTTE supporters arrested",
    description:
      "University of Jaffna lecturer M. Niththyanandan and his wife Nirmala arrested for providing medical treatment at their house for Seelan (LucasChales Anthony) until he was sent to India for treatment",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2001 11 18",
    title: "LTTE attack Buddhist temple",
    description:
      "Terrorists exploded a claymore mine at a jeep belonging to SRIPURA RAJA MAHA VIHARAYA, which was proceeding from PADAVIYA to MEDAVACHCHIYA with 03 x Buddhists Monks and the driver. Due to the explosion the Chief Incumbent of the RAJA MAHA VIHARAYA was killed and two other monks with the driver were injured. (Gonamariyawa)  ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1984 11 19",
    title: "LTTE assassination",
    description:
      "Terrorist had exploded a land mine killing Northern Commander Col ARIYAPPERUMA and 06 x Sldrs. (Thaleepalai, Jaffna)",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 19",
    title: "Sri Lanka Armed Forces",
    description: "OP KINIHIRA Stage IV was launched.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 21",
    title: "LTTE attack EPDP",
    description: "LTTE attacked EPDP Office in CHAVAKACHCHERI.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 11 22",
    title: "LTTE attack",
    description:
      "LTTE Leader of WANNI, FORK had driven an explosive laden Lorry into the Mannar Camp and exploded destroying the camp and himself.  ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1990 11 23",
    title: "LTTE attack army camp",
    description:
      "Attack on Mankulam Army Camp using explosive  filled with a lorry.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 23",
    title: "LTTE attack school",
    description:
      "Terrorists fired mortars towards MUTTUR Jetty and ALMAHAR Maha Vidyalaya. 02 x Students were killed. 11 x civilians were injured.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 25",
    title: "Oslo Declaraation",
    description:
      "The OSLO Declaration:- The document issued in support of the Peace Process in Sri Lanka at the conference held in OSLO on 25 Nov 2002 where Representatives from the Asia – Pacific Region, North America and Europe together with the GoSL and the LTTE participated.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 25",
    title: "Donor Conference",
    description:
      "The Donor Conference in Oslo has two wines to political and financial support. LTTE Team - S P THAMIL SELVAN, ANTON BALASINGHAM, ADELE BALALSINGHAM, KARUNA, Govt. Team  - Minister of Constitutional Affairs and Industrial Development G L PEIRIS, Minister of Economic Reforms, Science and Technology MILINDA MORAGODA, Maj. Gen. SANTHA KOTTEGODA. ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 25",
    title: "Protests against LTTE",
    description:
      "Residents in the DELFT ISLAND protest over LTTE stopping boat service from the mainland to the Island. This action is a result of the LTTE calling the total withdrawal of EPDP from DELFT.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1982 11 27",
    title: "Seelan killed by Sri Lanka Arm",
    description:
      " First LTTE Cadre(Charls Antoney alias Seelan) was killed by Security Forces",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1998 11 27",
    title: "LTTE aircraft",
    description:
      "Appearing of aircraft belonging to LTTE in MULLIYAWALAI area.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2002 11 27",
    title: "Police attacked",
    description:
      "About 200 persons had attacked the Police in DELFT ISLAND with clubs and stones when the Police attempted to arrest one GNANARAJ FOUMY who had threatened to close a boutique belonging to DELFT Pradeshiya Sabha. LTTE had held a festival to celebrate the Heroes Day in DELFT ISLAND and three were picketing campaign opposite the LTTE Office. ",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 28",
    title: "LTTE attack bus",
    description:
      "Private Bus Bearing No. 62 - 8281, which was proceeding on MEDAWACHCHIYA – KEBITHIGOLLEWA road, got caught to a pressure mine explosion at PANIKKIWEWA, ABHIMANPURA.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1984 11 29",
    title: "LTTE attack on villages - 1st village",
    description:
      "DOLLER and  farm attack killing of  62 civilians  (This is first such     nature attack and in 244 occasions LTTE attack innocent civilians settlements).",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1984 11 30",
    title: "LTTE attack on villages - 1st village",
    description: "Terrorists attacked the KENT FARM.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "2000 11 30",
    title: "LTTE kill soldiers",
    description:
      "Vavuniy-Mannar convoy was caught in a land mine explosion. 01 x civilian, 03 x officers, 05 x soldiers were killed at East of PIRAMANALANKULAM.",
    images: [],
    videos: [],
    links: [],
  },
  {
    date: "1984 12 01",
    title: "LTTE attack Sinhal village",
    description: "Armed terrorists shot dead 11 Sinhalese at Kokilai.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 12 01",
    title: "Jaffna captured by SL Army",
    description: "JAFFNA City recaptured by the Sri Lankan Forces.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 01",
    title: "SL Army launch attack on LTTE",
    description:
      "Operation Tiger Hunt was launched to clear Nagarkovil area of Jaffna peninsula.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 01",
    title: "SL Army launch attack on LTTE",
    description:
      "Operation Singing Fish and Operation Rivijaya cleared LTTE Jungle Bases in Batticaloa South.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 01",
    title: "LTTE attack Elephant Pass",
    description:
      "Terrorists launched a major attack on ELEPHANTPASS/VETTILAIKERNI/PARANTHAN Complex. Troops killed more than 500 x terrorists and suffered 150 deaths to-date.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 01",
    title: "LTTE violate ceasefire",
    description:
      "LTTE violated a two days cease-fire, which it had agreed to while aid workers immunized children against Polio (fired mortars at government troops in JFN).",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 01",
    title: "LTTE attack EPDP office",
    description:
      "A Bomb explosion at an EPDP Office in BCO Town injuring 23 x civilians and a member of the EPDP.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 01",
    title: "UNP goes to Norway",
    description:
      "UNF government seek the assistance of NORWAY as the facilitator and the LTTE also agreed to.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 12 02",
    title: "LTTE take Jaffna",
    description: "Fall of JFN Town.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 04",
    title: "LTTE attack passenger train",
    description:
      "A train moving from GAL-OYA Junction to TRINCOMALEE was damaged due to a land mine explosion. (Palampoddaru) ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 12 04",
    title: "SL Army launch Operation Jayasikuru",
    description: "Counter attack 6 on Op Jayasikurui at MANKULAM.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 12 05",
    title: "LTTE attack SL Army camp",
    description:
      "A Vehicle Bomb had exploded in front of the Puthukudirippu STF Camp. Simultaneously terrorists launched an attack on the camp from the rear causing heavy damages to the camp. The suicide bomber was identified as RANGAN..",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 12 05",
    title: "SL Army capture Oddusuddan",
    description: "Capture of ODDUSUDAN town by Op Rivibala troops.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 05",
    title: "SL Army launch attack on LTTE",
    description:
      "Operation KINIHEERA - IV Stage - II was launched in Sarasalai, 12 x soldiers were killed. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 05",
    title: "Genera Elections Sri Lanka - LTTE nominated TNA wins",
    description:
      "12th Parliament General Election was held. United National Front (UNF) led by United National Party (UNP) was retained to power. In this election four Tamil parties TULF, ACTC, TELO and EPRLF contested the election as a Tamil National Alliance (TNA).",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 06",
    title: "LTTE attack SL Army  ",
    description:
      "06 x Soldiers were killed and 05 x soldiers were wounded due to a claymore mine explosion. (Meesalai North)",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 06",
    title: "LTTE attack passenger bus",
    description:
      "A SLTB bus which was proceeding to COLOMBO from BATTICALOA was caught in a terrorist claymore mine explosion in Pillayaradi. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 07",
    title: "LTTE attack sinhal village",
    description:
      "LTTE enter the KURULUBEDDA Village and killed a group of villagers. (Welikanda)",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 08",
    title: "LTTE boat attack",
    description: "LTTE boat exploded in close quarters East of Pudukudurippu.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 11",
    title: "LTTE overrun STF camp",
    description: "LTTE attacked and over run the STF detachment. (Pulukunawa)",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1988 12 12",
    title: "LTTE attack sinhal village",
    description:
      "LTTE attack on the village, Sumedagama in TCO. 28 x Civilians were killed. 02 x Civilians were wounded.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 13",
    title: "LTTE bomb ",
    description:
      "A bomb explosion place at KATHANKUDY Town. The bomb had been planted in a telephone box and the telephone box was completely destroyed. (BCO)",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1978 12 12",
    title: "LTTE rob bank",
    description:
      "Robbery of Bank Money Amounting Rupees 8 Million in  Thirunalveli",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 14",
    title: "LTTE attack police",
    description:
      "A group of Police personnel were caught in a claymore mine explosion at Eravur.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1996 12 17",
    title: "LTTE suicide",
    description:
      "A Suicide Bomber on a motor cycle rammed on the vehicle carrying SSP SAHABANDU who was on his way to KARAITIVU from KALAVANCHIKUDI. (BCO)",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 17",
    title: "LTTE attack helicopter",
    description:
      "MI 24 helicopter CH 618 crashed into the EPS lagoon while providing close Air support to ground troops.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 17",
    title: "LTTE attack passenge bus",
    description:
      "Two bombs were exploded in 02 busses of Peoplized Transport Service in Divulapitiya.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1990 12 18",
    title: "LTTE assassination",
    description:
      "LTTE exploded a Land Mine in Panmedawachchiya killing 22 Div Commd Maj. Gen. CL WIJERATNE, SP WIJESEKARA and 08 x Soldiers.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 18",
    title: "LTTE attempt to assassinate President CBK",
    description:
      "A suicide cadre exploded herself at the Presidential Election Campaign Meeting held in Town Hall, Colombo 07 with the idea of assassinating HE the President Mrs. CHANDRIKA BANDARANAIKE KUMARATUNGA. The President's one of her eye was injured. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 18",
    title: "LTTE assassination",
    description:
      "When a United National Party Propaganda Meeting was in progress near the Lourdu Grotto, Ja-Ela a Suicide Bomber exploded himeself killing Retired Maj. Gen. C L ALGAMA including 12 x persons and injuring 45 x other participants.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 12 15",
    title: "LTTE attack sinhal village",
    description:
      "LTTE attack on a village, Devalegodella, PLN. 07 x Civilians were killed.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 16",
    title: "SL Army launch attack on LTTE",
    description: "Operation KINIHIRA V Stage -II was launched in NUNAVIL area.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 19",
    title: "LTTE attack public place",
    description:
      "LTTE set off a  Improvised Explosive Device partially damaging a Telecom Distribution Box in Kallady.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 19",
    title: "LTTE ceasefire",
    description:
      'LTTE announced the declaration of a month long "UNILATERAL CEASE-FIRE" from midnight. 24th December 2001 till 24th January 2002.   ',
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 21",
    title: "GOSL agree to ceasefire",
    description:
      "The Govt. of SL reciprocated by announcing cessation for hostilities as from the same date for a month as declared by the LTTE.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 12 22",
    title: "LTTE abduct fishermen",
    description:
      "LTTE abducted 06 fishermen and later killed them in Morawewa, TCO.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2000 12 22",
    title: "SL Army launch attack on LTTE",
    description: "OP KINIHIRA VII – KAITHADY/ARIYALAI.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 23",
    title: "Memo to Kofi Annan UNSG",
    description:
      'A memorandum had been sent to UN Secretary General KOFFI ANNAN with copies to the USA, UK, India, Norway Canada, Australia and the member States of the European Union, stating that “Tamil Community in the North East Province overwhelmingly supported the Tamil National Alliance which stood for accepting the LTTE as the Sole Representative of the Tamils in Sri Lanka by Community Leaders, Civil Society Groups, NGOs and Trade Unions in TRINCOMALEE". ',
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 12",
    title: "Ceasefire",
    description:
      "Declaration of Ceasefire by the LTTE and UNF government responded. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 25",
    title: "LTTE visit SL Army camps",
    description:
      "THURAI (LTTE’S Deputy Leader Political Leader BCO/AMP) had made an official visit in areas held by the SF in BATTICALOA SOUTH and had discussion with the STF at PADDIRIPPU Bridge Camp.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1992 12 26",
    title: "LTTE explode mine",
    description:
      "A Maruti Jeep (17 – 5747) carrying the Additional AG (BCO), AGA (VALACHCHENAI) was caught in a land mine explosion between 120 – 121 Km Posts at Vakaneri South on WELIKANDA – VALACHCHENAI Road. 06 x Civ were killed.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1998 12 26",
    title: "LTTE assassinaion of Tamils",
    description:
      "Mr. PONNADURAI MATHIMUGARAJAH - District Secretary of the TULF, Nallur/Chairman of the International M.G. Ramachandran Organisation. (Suicide Attack) (Nallur, JFN) killed by LTTE",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 27",
    title: "Norway tobe mediator",
    description:
      "Norwegian Govt. announced that they are ready to facilitate to brief the Govt. and the LTTE at negotiation table if both parties accepted the mediator role by the Norwegian Govt.  ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1997 12 28",
    title: "LTTE bomb explodes",
    description:
      "A Lorry (ISUZU - ELF-250) which was transporting high powered bombs by LTTE suicide cadres accidently exploded near the MAGALKLE BRIDGE on MTR - GLE Road. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1993 12 29",
    title: "LTTE boat suicide",
    description:
      "A Super DVORA P- 464 was attacked by a Terrorist Suicide Boat at POINT PEDRO.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1995 12 30",
    title: "LTTE suicide",
    description:
      "A Suicide Bomber of the LTTE exploded himself near the SF vehicle, which was proceeding from BATTICALOA towards KALLADY and the vehicle was slightly damaged.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1999 12 30",
    title: "LTTE suicide boats",
    description:
      "The SLN had identified a cluster of LTTE boats including 02 suicidal boats approaching the Navy Detachment at Kilaly. The enemy had opened fire and the troops retaliated. A suicide boat had rammed on to the Naval boat.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 12 31",
    title: "LTTE attack Muslim village",
    description:
      "LTTE attacked the KATHANKADU, Muslims village in BCO. 30 x Civilians were killed.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1987 12 31",
    title: "LTTE attack Sinhal village",
    description:
      "Terrorists attacked the villagers and burnt 15 houses in Mahadivulwewa, TCO. 08 x Civilians were killed and 09 x Civilians were wounded.",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "1994 12 12",
    title: "LTTE assassination of Tamils",
    description:
      "Mr. ARUMUGAM CHELLIAH @ KARAVAI KANDASAMI (Vice President of DPLF/Leader of PLOTE) shot dead at Dehiwala by the LTTE cadre. ",
    videos: [],
    images: [],
    links: [],
  },
  {
    date: "2001 12 31",
    title: "LTTE & RW",
    description:
      "Prime Minister RANIL WICKRAMASINGHE had sent an official letter to Norwegian Prime Minister KJELL MAGNE BONDEVIK. On matter include bringing a Ceasefire Monitoring Committee help to bring LTTE to negotiating table. ",
    videos: [],
    images: [],
    links: [],
  },
];
