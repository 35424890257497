import { Avatar, Col, Divider, Row } from "antd";
import moment from "moment";
import { warCrimesData } from "../crimesData/warCrimesData";
import { useEffect, useState } from "react";

export const MainList = () => {
  const [warCrimeListData, setWarCrimeListData] = useState([{ date: "" }]);

  useEffect(() => {
    setWarCrimeListData(warCrimesData);
  }, []);

  const today = moment().format("Do MMM");
  const todayEvents = warCrimeListData.filter(
    (item) => moment(item.date, "YYYY MM DD").format("Do MMM") === today
  );

  const listWarCrimes = todayEvents.map((data, index) => {
    return (
      <Col span={24} key={index}>
        <Row
          gutter={[14]}
          style={{
            backgroundColor: "white",
            margin: "10px",
            padding: "15px",
            borderRadius: "5px",
            maxHeight: "100%",
            opacity: "0.8",
            maxWidth: "600px",
          }}
        >
          <Col
            span={3}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Avatar
                style={{ backgroundColor: "red" }}
                size={"large"}
                shape="square"
              >
                {moment(data.date, "YYYY MM DD").format("YYYY")}
              </Avatar>
            </div>
          </Col>
          <Col span={21}>
            <div style={{ textAlign: "left" }}>
              <strong>
                <span style={{ fontSize: "16px" }}>{data.title}</span>
              </strong>
              <p>{data.description}</p>
            </div>
          </Col>
          <Divider style={{ margin: "0px" }}></Divider>
        </Row>
      </Col>
    );
  });

  return (
    <div>
      <Col
        style={{
          backgroundColor: "white",
          margin: "10px",
          padding: "15px",
          borderRadius: "5px",
          maxHeight: "100%",
          opacity: "0.8",
        }}
        span={24}
      >
        <h2>{today}</h2>
      </Col>
      {listWarCrimes}
    </div>
  );
};
