import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

export const NavBar = () => {
  return (
    <Navbar style={{ backgroundColor: "black" }} sticky="top">
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand style={{ color: "white" }}>
            <span>
              {"LTTE WAR CRIMES "}
              <small>{"| On this Day"}</small>
            </span>
          </Navbar.Brand>
        </Link>
      </Container>
    </Navbar>
  );
};
