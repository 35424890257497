import { Col, Row } from "antd";
import { MainList } from "../components/mainList/mainList";

export const LandingPage = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        justifyContent: "center",
        display: "flex",
        height: "100%",
        minHeight: "100vh",
        backgroundImage: `url(https://pcdn.columbian.com/wp-content/uploads/2019/04/AP19113067929501.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="overlay"></div>
      <Row style={{ maxWidth: "900px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <div style={{ marginTop: "30px" }}>
            <h1>
              <span
                style={{
                  fontSize: "3rem",
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px 10px 5px 10px",
                }}
              >
                #LestWeForget
              </span>
            </h1>
          </div>
          <div>
            <MainList />
          </div>
        </Col>
      </Row>
    </div>
  );
};
